import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from '../axiosConfig';
import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import { getter } from "@progress/kendo-react-common";
import { filterBy } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import '@progress/kendo-theme-default/dist/all.css';
import './GeneralGridStyle.css';
import CustomLockedCell from './customizedColumns/CustomLockedCell';
import ChangeOrderCell from './customizedColumns/ChangeOrderCell';
import CodeSplitCell from './customizedColumns/CodeSplitCell';
import SidePanel from './SidePanel';
import useProfileData from '../services/ProfileDataService';
import CustomDropdownCell from './customizedColumns/CustomDropdownCell';
import AssignButton from './customizedColumns/AssignButton';
import CustomFilter from './customizedFilters/CustomFilter';
import Loader from './Loader';
import Toast from './ToastNotification';
import { truncateEmail } from '../Utilities/utils';
import { useMsal } from "@azure/msal-react";
import Modal from './Modal';
import LogOutService from '../services/LogOutService';
import { getCurrentLine, subscribeToCurrentLineChanges  } from '../services/CurrentLineService';
import useEmployeeRole from '../Utilities/EmployeeRole';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from "@progress/kendo-react-common";
import { xIcon } from "@progress/kendo-svg-icons";
import { DPSA_LINE } from '../Utilities/constants';

const DATA_ITEM_KEY = "orderDetailId";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const ModalAssignation = (props) => {
  const { visible, responseData, onClose, numSelected, isUnassign } = props;

  const errorIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <mask id="mask0_89_2934" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
      <rect x="0.785645" y="0.846191" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_89_2934)">
      <path d="M12.7856 17.8462C13.069 17.8462 13.3065 17.7504 13.4981 17.5587C13.6898 17.367 13.7856 17.1295 13.7856 16.8462C13.7856 16.5629 13.6898 16.3254 13.4981 16.1337C13.3065 15.942 13.069 15.8462 12.7856 15.8462C12.5023 15.8462 12.2648 15.942 12.0731 16.1337C11.8815 16.3254 11.7856 16.5629 11.7856 16.8462C11.7856 17.1295 11.8815 17.367 12.0731 17.5587C12.2648 17.7504 12.5023 17.8462 12.7856 17.8462ZM11.7856 13.8462H13.7856V7.84619H11.7856V13.8462ZM12.7856 22.8462C11.4023 22.8462 10.1023 22.5837 8.88564 22.0587C7.66898 21.5337 6.61064 20.8212 5.71064 19.9212C4.81064 19.0212 4.09814 17.9629 3.57314 16.7462C3.04814 15.5295 2.78564 14.2295 2.78564 12.8462C2.78564 11.4629 3.04814 10.1629 3.57314 8.94619C4.09814 7.72952 4.81064 6.67119 5.71064 5.77119C6.61064 4.87119 7.66898 4.15869 8.88564 3.63369C10.1023 3.10869 11.4023 2.84619 12.7856 2.84619C14.169 2.84619 15.469 3.10869 16.6856 3.63369C17.9023 4.15869 18.9606 4.87119 19.8606 5.77119C20.7606 6.67119 21.4731 7.72952 21.9981 8.94619C22.5231 10.1629 22.7856 11.4629 22.7856 12.8462C22.7856 14.2295 22.5231 15.5295 21.9981 16.7462C21.4731 17.9629 20.7606 19.0212 19.8606 19.9212C18.9606 20.8212 17.9023 21.5337 16.6856 22.0587C15.469 22.5837 14.169 22.8462 12.7856 22.8462Z" fill="#FF4949"/>
    </g>
    </svg> );
  const successIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <mask id="mask0_89_3399" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
      <rect x="0.785645" y="0.846191" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_89_3399)">
      <path d="M11.3856 17.4462L18.4356 10.3962L17.0356 8.99619L11.3856 14.6462L8.53564 11.7962L7.13564 13.1962L11.3856 17.4462ZM12.7856 22.8462C11.4023 22.8462 10.1023 22.5837 8.88564 22.0587C7.66898 21.5337 6.61064 20.8212 5.71064 19.9212C4.81064 19.0212 4.09814 17.9629 3.57314 16.7462C3.04814 15.5295 2.78564 14.2295 2.78564 12.8462C2.78564 11.4629 3.04814 10.1629 3.57314 8.94619C4.09814 7.72952 4.81064 6.67119 5.71064 5.77119C6.61064 4.87119 7.66898 4.15869 8.88564 3.63369C10.1023 3.10869 11.4023 2.84619 12.7856 2.84619C14.169 2.84619 15.469 3.10869 16.6856 3.63369C17.9023 4.15869 18.9606 4.87119 19.8606 5.77119C20.7606 6.67119 21.4731 7.72952 21.9981 8.94619C22.5231 10.1629 22.7856 11.4629 22.7856 12.8462C22.7856 14.2295 22.5231 15.5295 21.9981 16.7462C21.4731 17.9629 20.7606 19.0212 19.8606 19.9212C18.9606 20.8212 17.9023 21.5337 16.6856 22.0587C15.469 22.5837 14.169 22.8462 12.7856 22.8462Z" fill="#13CE66"/>
    </g>
  </svg> );
  const warningIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <mask id="mask0_89_1946" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect x="0.785645" y="0.103516" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_89_1946)">
        <path d="M1.78564 21.1035L12.7856 2.10352L23.7856 21.1035H1.78564ZM12.7856 18.1035C13.069 18.1035 13.3065 18.0077 13.4981 17.816C13.6898 17.6243 13.7856 17.3868 13.7856 17.1035C13.7856 16.8202 13.6898 16.5827 13.4981 16.391C13.3065 16.1993 13.069 16.1035 12.7856 16.1035C12.5023 16.1035 12.2648 16.1993 12.0731 16.391C11.8815 16.5827 11.7856 16.8202 11.7856 17.1035C11.7856 17.3868 11.8815 17.6243 12.0731 17.816C12.2648 18.0077 12.5023 18.1035 12.7856 18.1035ZM11.7856 15.1035H13.7856V10.1035H11.7856V15.1035Z" fill="#FFCC3D"/>
    </g>
    </svg> );
  
  const xButton = <Button
    onClick={onClose} 
    style={{
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        borderRadius: '180px',
        backgroundColor: '#F2F2F2',
        border: 'none',
        display: 'flex', 
        alignItems: 'center',  
    }}> <SvgIcon icon={xIcon} size={'medium'} color='#8D8D8D'></SvgIcon>
    </Button>

  const successfullyAssigned = responseData?.successfullyAssignedOrders?.length;
  const successfullyUnassigned = responseData?.removedAssignments?.length;
  const successMessage = "was successfully assigned.";
  const notAssignedMessage = "was not assigned because it is already taken by";

  const getFormattedMessage = (order) => {
    if (order.includes(successMessage)) {
      return (
        <>
          <span><b>{order.split(successMessage)[0]}</b>{successMessage}</span>
        </>
      );
    } else if (order.includes(notAssignedMessage)) {
      const [beforeMessage, afterMessage] = order.split(notAssignedMessage);
      return (
        <>
          <span><b>{beforeMessage}</b>{notAssignedMessage}<b>{afterMessage}</b></span>
        </>
      );
    } else {
      return order;
    }
  };

  const getHeight = (numSelected) => {
    if (numSelected < 2) {
      return '224px';
    } else if (numSelected >= 2 && numSelected <= 8) {
      return 'auto';
    } else {
      return '600px';
    }
  };

  return (
    visible && (
      <Dialog 
        visible={visible}
        height={getHeight(numSelected)}
        width={'512px'}
      >
        <div style={{ padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', height: '100%', gap: '8px'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%'}}>
            <div style={{ gap: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px'}}>
              {errorIcon}
              <div className='roboto-19-700'>
              {isUnassign 
                ? `${successfullyUnassigned} out of ${numSelected} orders have been unassigned successfully`
                : `${successfullyAssigned} out of ${numSelected} orders have been assigned successfully`}
              </div>
            </div>
            {xButton}
          </div>
          {responseData?.messages?.map((order, index) => (
            <div className='roboto-15-400' style={{ textAlign: 'left', width: '100%', borderBottom: '1px solid #EBEBEB', paddingBottom: '8px'}}>
              <div key={index} style={{ gap: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {order.includes("successfully") ? successIcon : warningIcon}
                {getFormattedMessage(order)}
              </div>        
            </div>
          ))}
          <div style={{ marginTop: 'auto', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <button className='roboto-15-500 primary-button' onClick={onClose}>Close</button>
          </div>
        </div>
      </Dialog>
    )
  );
};

const GeneralGrid = ({ columns = [], dateRange,
  filter, setFilter,
  prevFilter, setPrevFilter,
  currentFilter, setCurrentFilter, onRemoveAll,
  setRemoveFilter, dropdownFilters, setDropdownFilters, removeFilter,
  selectedOptions, setSelectedOptions, onDrafthoursEdit, multipleAssignation,
  unassignDesigners, isUnassign, setIsUnassign, chipsRemoved, setChipsRemoved, scheduleData, setScheduleData,
  CodeStringFilters, setCodeitemsChipsData
}) => {
  
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const storedCurrentPage = localStorage.getItem('currentPageGeneralView');
  const storedPage = localStorage.getItem('pageGeneralView');
  const storedStartDate = localStorage.getItem('startDate');
  const storedEndDate = localStorage.getItem('endDate');


  const [pageSize, setPageSize] = useState(storedPage ? JSON.parse(storedPage).take: 50);
  const [sort, setSort] = useState([{ field: "isPriority", dir: "desc" }]);
  const [page, setPage] = useState(
    { skip: storedPage ? JSON.parse(storedPage).skip : 0,
     take: storedPage ? JSON.parse(storedPage).take : pageSize 
    });

  const { token, profileImage, profileImageData } = useProfileData();

  
  const [currentPage, setCurrentPage] = useState(storedCurrentPage ? JSON.parse(storedCurrentPage) : 1);
  const [totalRows, setTotalRows] = useState(0);

  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const { accounts } = useMsal();  
  const { instance } = useMsal();

  const [department, setDepartment] = useState(getCurrentLine().ProdFamilyId);
  const viewAlias = 0;
  const [productName, setProductName] = useState(getCurrentLine().ProdFamilyName);

  const fetchData = async () => {
  
    try {
            
      setLoading(true);            

      if (token) {
        const response = await axios.post(
          `/Department/pagedFilter/${encodeURIComponent(sort?.[0]?.field || 'isPriority')}`,
          {
            prodFamilyId: department,
            dateColumn: 'DraftingDueDate',
            sort: sort?.[0]?.dir || 'des',
            skip: page.skip,
            take: page.take,
            startDate: dateRange.startDate || storedStartDate,
            endDate: dateRange.endDate || storedEndDate,
            filter: JSON.stringify(filter, null, 2),
            viewAlias: viewAlias,
            CodeStringFilter : JSON.stringify(CodeStringFilters, null, 2),
            productName: productName,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const usersWithIndex = response.data.items.map((user, index) => ({
          ...user,
          rowIndex: index + 1 + page.skip,
        }));

        setScheduleData({
          TotalRows: response.data.TotalRows,
          Items: usersWithIndex,
        });

        setUsers(usersWithIndex);
        setTotalRows(response.data.totalRows);
        setLoading(false);

      }
    } catch (error) {

      if (error.response) {

        setToast({ showToast: true, message: error.response.data, type: 'error' });
        if(error.response.status === 401) LogOutService(instance);                

      } else {
        setToast({ showToast: true, message:  error.message, type: 'error' });
      }

      setLoading(false);
    }
  };

  //Notice changes on current Line service
  useEffect(() => {    
    const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {      
      setDepartment(newCurrentLine.ProdFamilyId);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setToast({ showToast: false });
    setIdtoast(idtoast + 1);
    fetchData();        
  }, [token, page.skip, page.take, sort, dateRange, filter, department, CodeStringFilters]);

  useEffect(() => {
    setPage({ skip: 0, take: pageSize });
    setCurrentPage(1);
  }, [dateRange, sort, filter]);

  const [filtro, setFiltro] = useState(null)

  // **************Static filters approach**************

  useEffect(() => {
    const formattedCodeItems = CodeStringFilters.reduce((acc, item) => {
      acc[item.description] = acc[item.description] || [];
      acc[item.description].push(`${item.Value}`);
      return acc;
    }, {});

    setCodeitemsChipsData(formattedCodeItems);
  }, [CodeStringFilters]);

  const uploadProfileImageData = async () => {  
    try {
      
      const fileExtension = profileImageData.headers['content-type'].split('/').pop();
      const fileName = `${truncateEmail(accounts[0].username, 50)}.${fileExtension}`;      

      const formData = new FormData();
      
      // convert string to Blob
      const byteCharacters = atob(profileImage.split(',')[1]); //delete prefix (data:image/jpeg;base64,)
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: profileImageData.headers['content-type'] });

      formData.append('file', blob, fileName);
  
      if (token) {
        const saveUrl = `/uploadProfileImage/blob?&containerName=${encodeURIComponent("employeeprofileimages")}`;
  
        const uploadResponse = await axios.post(saveUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        });
        
        if (fileName) { 
          const enpointUrl = `/UpdateEmployee?&employeeName=${encodeURIComponent(accounts[0].name)}&employeeEmail=${encodeURIComponent(accounts[0].username)}&employeePicURL=${encodeURIComponent(fileName)}&aDAccount=${encodeURIComponent(truncateEmail(accounts[0].username, 50))}&plantId=${encodeURIComponent('2')}&isActive=${encodeURIComponent('1')}&author=${encodeURIComponent(truncateEmail(accounts[0].username, 50))}`;
        
          const response = await axios.put(enpointUrl, null, {
            headers: {
              Authorization: token,
            },
          });
        }

      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };  

  useEffect(() => {   
    uploadProfileImageData(); 
  }, [profileImage]);

  const filterConfig = {
    text: "text",
    number: "numeric",
    boolean: "boolean",
    date: "date",
    dropdown: {
      filterCell: (props) => {
        const { field } = props;
        return (
          <CustomFilter
            {...props}
            data={users}
            field={field}
            currentFilterField={field}
            onSelectionChange={handleCustomFilterChange}
            onRemoveAll={onRemoveAll}
            selectedOptions2={currentFilter}
            selectedFilter={filtro}
            removeFilter={removeFilter}
            prevFilter={prevFilter}
            filter={dropdownFilters}   
            viewOption={viewAlias}                     
          />
        );
      }
    }
  };

  const handlePriorityUpdate = async (unmarkSuccess) => {
    if (unmarkSuccess == true) {
      setIdtoast(idtoast + 1);
      setToast({ showToast: false });
      setToast({ showToast: true, message: 'Order unmarked successfully', type: 'success' });
    }
    await fetchData();
  };

  const handleAssignOrder = async (text, state, multi) => {

    if(!text) text= 'Order assigned successfully'
    if(!state) state= 'success'
    if (multi === true) {
      setModalVisible(true);
    } else {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: text, type: state });
    }

    await fetchData();
  };

  const handleUnassignOrder = async () => {

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Order unassigned successfully', type: 'success' });

    await fetchData();
  };

  const handleAssignError = async (error) => {

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    
    if(error?.response?.data?.message)      
      setToast({ showToast: true, message: error.response.data.message, type: 'error' });
    else      
      setToast({ showToast: true, message: 'An error occurred while assigning the order', type: 'error' });

  };

  const showToastError = async () => {

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'An unexpected error occurred', type: 'error' });
    
  };
  
  const handleCustomFilterChange = (selectedOptions, currentFilterField) => {    
    
    setFilter((prevFilter) => {
      if (!prevFilter) {
        // if there are not a existing filter creatte a new one
        return {
          logic: "or",
          filters: [
            ...selectedOptions.map((option) => ({
              field: currentFilterField,
              operator: "eq",
              value: option,
            })),
          ],
        };
      } else {
        // if there already exist a filter add current selection
        const updatedFilters = [
          ...prevFilter.filters.filter((filter) => filter.field !== currentFilterField),
          ...selectedOptions.map((option) => ({
            field: currentFilterField,
            operator: "eq",
            value: option,
          })),
        ];        

        return {
          ...prevFilter,
          filters: updatedFilters,
        };
      }
    });
    
    setFiltro(currentFilterField);
    setCurrentFilter(selectedOptions);

    localStorage.setItem('currentFilterGeneralView', JSON.stringify(selectedOptions));
    setRemoveFilter(false);
    const allFields = filter?.filters?.map(filter => filter.field) || [];
    allFields.unshift(currentFilterField);
    const allFields2 = [...new Set(allFields)];
    setPrevFilter(allFields2);

    localStorage.setItem('currentPrevFilterGeneralView', JSON.stringify(allFields2));

    setDropdownFilters(filter?.filters);    
  };


  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const newPage = {
      skip: event.page.skip,
      take: targetEvent.value === 'All' ? totalRows : event.page.take,
    };

    setPage(newPage);    
    setCurrentPage(newPage.skip / pageSize + 1);

    localStorage.setItem('pageGeneralView', JSON.stringify(newPage));
    localStorage.setItem('currentPageGeneralView', JSON.stringify(newPage.skip / pageSize + 1));
  };
  const handleFilterChange = (event) => {
    if (!event.filter || !event.filter.filters.length) {
      setFilter({
        logic: "and",
        filters: []
      });
      return;
    }
  
    const updatedFilters = event.filter.filters.map(filter => {
      if (filter.field === 'mgmt') {
        return {
          ...filter,
          value: filter.value ? 'Delta' : 'New'
        };
      }
      return filter;
    });
  
    setFilter({
      ...event.filter,
      filters: updatedFilters
    });
  };

  const filteredUsers = filterBy(users, filter);
  const sortedUsers = orderBy(filteredUsers, sort);

  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    setSelectedColumns(columns);
  }, [columns], [sortedUsers]);

  useEffect(() => {
      localStorage.setItem('filtersGeneralView', JSON.stringify(filter, null, 2));
      const sotredFilters = JSON.stringify(filter?.filters, null, 2);

      if(sotredFilters)
      localStorage.setItem('dropDownFiltersGeneralView', sotredFilters);    
  }, [filter]);

  const [selectedColumnFields, setSelectedColumnFields] = useState(columns.map(column => column.field));

  useEffect(() => {
    setSelectedColumnFields(columns.map(column => column.field));
  }, [columns]);

  const [openForm, setOpenForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState();
  const [data, setData] = React.useState(sortedUsers);

  const enterEdit = async (item) => {
    
    const updatedItem = {
      ...item,
      orderNumber: 0,
      orderNumberChecker: 0,
    };

    setOpenForm(true);
    setEditItem(updatedItem);

};

  const handleDesignerDetailHrsChange = (newDesignerDetailHrs,) => {
    setEditItem((prevItem) => ({
      ...prevItem,
      orderNumber: newDesignerDetailHrs      
    }));
  };

  const handleDesignerCheckerHrsChange = (newDesignerCheckerHrs) => {
    setEditItem((prevItem) => ({
      ...prevItem,
      orderNumberChecker: newDesignerCheckerHrs
    }));
  };


  const handleSubmit = (event) => {
    let newData = data.map((item) => {
      if (event.item === item.item) {
        item = {
          ...event,
        };
      }
      return item;
    });
    setData(newData);



    setOpenForm(false);
  };

  const [profileImgAssigned, setProfileImgAssigned] = useState();
  const [designerName, setName] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const { employeeRole, employeeData } = useEmployeeRole();

  const downloadFile = async (imageName) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    let errorMessage;
    if (token) {
      const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(imageName)}&departmentsFolder=${encodeURIComponent("")}&departmentFolder=${encodeURIComponent("")}&orderNumFolder=${""}&containerName=${encodeURIComponent("employeeprofileimages")}&isProfileImage=${encodeURIComponent(true)}`;
      try {
        const response = await axios.get(downloadUrl, {
          headers: {
            Authorization: token,
          },
          responseType: 'blob',
        });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const imageUrl = URL.createObjectURL(blob);

      const imageSrc = imageUrl;

      if (imageSrc == null) {
        setProfileImgAssigned('/DefaultProfile.png');
      }
      else {
        setProfileImgAssigned(imageSrc);
      }

      } catch (error) {
        if (error.response) {

          const status = error.response.status;
          const errorMessages = {
            401: 'Expired Token',
            500: 'The file does not exist on the blob storage'
          };

          errorMessage = errorMessages[status] || 'An error occurred';
        } else {
          errorMessage = error.message || 'An unexpected error occurred';
        }

        setToast({ showToast: true, message: errorMessage, type: 'error' });
      }
    }
  };

  const handleStatusChange = async (status, orderDetailId) => {

    try {             
      const updateUrl = `/updateOrderStatus/${encodeURIComponent(orderDetailId ? orderDetailId: editItem.orderDetailId)}?esoiStatusId=${encodeURIComponent(status)}&Author=${encodeURIComponent(truncateEmail(accounts[0].username,50))}`;
  
      const response = await axios.put(
        updateUrl,
        null, 
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json', 
          }
        }
      );
      
      await fetchData();
    }
    catch (error) {
      handleAssignError(error);
    };                 
  };

  const handleChange = async (selectedStatus) => {
      
    const status = getSelectedStatusID(selectedStatus);          
    try {             
      const updateUrl = `/updateOrderStatus/${encodeURIComponent(editItem.orderDetailId)}?esoiStatusId=${encodeURIComponent(status)}&Author=${encodeURIComponent(truncateEmail(accounts[0].username,50))}`;

      const response = await axios.put(
        updateUrl,
        null, 
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json', 
          }
        }
      );     

      if (response.status === 200 && editItem.itemType === 'SPC') {        
        if(status === 8 && (editItem.esoistatusDesc !== selectedStatus))
        {                    
          handleUnassignStatus();
        }

             
      }
      await fetchData();     
    }
    catch (error) {
      showToastError();
    };         
    
};



const handleUnassignStatus = async () =>{        

  let valuesList = [];

  let employeeTitleRelIdValue = getEmployeeTitleRelId(1);

  let mappedValues = {
    orderDetailId: editItem.orderDetailId,
    employeeEmail: accounts[0].username,
    employeeTitleId: 1,
    hoursActual: 0,
    author: truncateEmail(accounts[0].username,50),
    isActive: 0,
    userRole: employeeRole,
    employeeTitleRelId: employeeTitleRelIdValue,
  };

  valuesList = [mappedValues];

  try {
      if (token && editItem.orderDetailId) {                    
        const response = await axios.post(
          `orderAssignation/`,            
            valuesList,            
          {
            headers: {
              Authorization: token,
            },
          }
        ); 
      }
    
  
  } catch (error) {
    showToastError();
  };  
};

  function getSelectedStatusID(eValue) {      
    switch (eValue) {
      case 'Order Processing':
        return 1;
      case 'Not started':
        return 2;
      case 'WIP':
        return 3;
      case 'Done':
        return 4;
      case 'Submitted':
        return 5;
      case 'Released':
        return 6;
      case 'Ready for Check':
        return 7;
      case 'Ready for Drafting':
        return 8;
      default:
        return 2;
    }
  }

  const [responseData, setResponseData] = useState([]);

  const handleMultipleAssignment = async (valuesList, employeeTitleId) => {    
    if( multipleAssignation.length <= 0) return;

    try {
      const response = await axios.post(
        `orderAssignation/`,            
         valuesList,            
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // Filter duplicated messages
      const filterMessages = (messages) => {
        const seen = new Set();
        return messages.filter(message => {
          const code = message.split(' ')[0];
          if (seen.has(code)) {
            return message.includes('was not assigned');
          } else {
            seen.add(code);
            return true;
          }
        }).reduce((acc, message) => {
          const code = message.split(' ')[0];
          if (!acc.some(msg => msg.split(' ')[0] === code)) {
            acc.push(message);
          }
          return acc;
        }, []);
      };

      // Remove duplicates inside successfullyAssignedOrders
      const uniqueSuccessfullyAssignedOrders = [...new Set(response.data.successfullyAssignedOrders)];


      if (response?.data?.messages) {
        response.data.messages = filterMessages(response.data.messages);
      }

      response.data.successfullyAssignedOrders = uniqueSuccessfullyAssignedOrders;      
      setResponseData(response?.data);      
      handleAssignOrder('Order updated successfully', '', true);

      if (response?.data?.successfullyAssignedOrders?.length > 0 && (employeeTitleId === 1 || employeeTitleId === 3)) {
        const processedIds = new Set(); 
        
        for (const assignedOrderId of response.data.successfullyAssignedOrders) {          
          if (!processedIds.has(assignedOrderId)) {
            processedIds.add(assignedOrderId);
  
            const correspondingValue = selectedOptions.find(value => value.orderDetailId === assignedOrderId);
  
            if (correspondingValue && correspondingValue.itemType === 'SPC') {              
              const orderStatus = correspondingValue.esoistatusDesc === 'Ready for Drafting' ? 3 : 1;
              handleStatusChange(orderStatus, correspondingValue.orderDetailId);
            }
          }
        }

        
        fetchData();      
    } 
      
    } 
    catch (error) {            
      handleAssignError(error);
    };      
    

    // setSelectedOptions([]);
    setOpenForm(false);    
  }

  const updateValuesList = (valuesList, selectedOptions) => {
    return valuesList.filter(value => {
      const selectedOption = selectedOptions.find(option => option.orderDetailId === value.orderDetailId);
      
      if (selectedOption) {
        const isCheckerMatch = value.employeeTitleId === 2 && chipsRemoved.designerChecker.some(checker => checker.designerName === selectedOption.designerChecker);
        const isDetailMatch = value.employeeTitleId === 1 && chipsRemoved.designerDetail.some(detailer => detailer.designerName === selectedOption.designerDetail);
        
        // if there is a unassignChecker or unassignDesigner that match keep in the list
        return isCheckerMatch || isDetailMatch;
      }
      return false;
    });
  };

  const evaluateUnassignation = () => {
    if (chipsRemoved.designerChecker.length > 0 && chipsRemoved.designerDetail.length > 0) {
      return 3;
    } else if (chipsRemoved.designerDetail.length > 0) {
      return 1;
    } else if (chipsRemoved.designerChecker.length > 0) {
      return 2;
    } else {
      return 0; 
    }
  };

  const handleMultipleUnassignment = async () => {
    
    let newValuesList = []; 

    if(selectedOptions?.length > 0)
    {          
      newValuesList = selectedOptions.flatMap(value => {
        const employeeTitleId = evaluateUnassignation();
        
        if (employeeTitleId === 3) { //unassignation by detailer and checker
          return [
            {
              orderDetailId: value.orderDetailId,
              employeeEmail: accounts[0].username,
              employeeTitleId: 1,
              hoursActual: 0,
              author: truncateEmail(accounts[0].username, 50),
              isActive: 0,
              userRole: employeeRole,
              employeeTitleRelId: getEmployeeTitleRelId(1),
            },
            {
              orderDetailId: value.orderDetailId,
              employeeEmail: accounts[0].username,
              employeeTitleId: 2,
              hoursActual: 0,
              author: truncateEmail(accounts[0].username, 50),
              isActive: 0,
              userRole: employeeRole,
              employeeTitleRelId: getEmployeeTitleRelId(2),
            }
          ];
        } else {
          return {
            orderDetailId: value.orderDetailId,
            employeeEmail: accounts[0].username,
            employeeTitleId: employeeTitleId,
            hoursActual: 0,
            author: truncateEmail(accounts[0].username, 50),
            isActive: 0,
            userRole: employeeRole,
            employeeTitleRelId: getEmployeeTitleRelId(employeeTitleId),
          };
        }
      });
    }        
    
    const updatedValuesList = updateValuesList(newValuesList, selectedOptions);
    setChipsRemoved({
      designerChecker: [],
      designerDetail: []
    });

    try {
      const response = await axios.post(
        `orderAssignation/`,            
        updatedValuesList,            
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response?.data?.messages) {
        response.data.messages = response.data.messages;
      }

      // response.data.successfullyAssignedOrders = uniqueSuccessfullyAssignedOrders;      
      setResponseData(response?.data);      
      handleAssignOrder('Order Unassigned successfully', '', true);

      if (response?.data?.removedAssignments?.length > 0) {        
        for (const assignedOrderId of response.data.removedAssignments) {  
          const correspondingValue = selectedOptions.find(value => value.orderDetailId === assignedOrderId);
          const correspondingUpdate = updatedValuesList.find(value => value.orderDetailId === assignedOrderId);
          
          if (correspondingValue && correspondingValue.itemType === 'SPC') {
            const orderStatus = correspondingValue.esoistatusDesc === 'Order Processing' ? 2 : 8;
            
            if (correspondingUpdate && correspondingUpdate.employeeTitleId === 1) {
              handleStatusChange(orderStatus, correspondingValue.orderDetailId);
            }
          }
        }
      }
        
        fetchData();      
          
    } 
    catch (error) {            
      handleAssignError(error);
    };      
    

    // setSelectedOptions([]);
    setOpenForm(false);    
  }

  function getEmployeeTitleRelId(designerType) {      
    let employeeTitleRelIdValue;

    employeeData?.forEach(item => {
        if (item.employeeTitleId === designerType) {
            employeeTitleRelIdValue = item.employeeTitleRelId;
        }
    });

    return employeeTitleRelIdValue; 
  }


  const handleSubmitOrderAssignation = async (values, notShow = true) => {    

    setResponseData([]);
    var showModal = false;

    const userName = accounts[0].name;     
    
    const designerDetailChange = !(values?.designerDetail == editItem?.designerDetail)
    const designerCheckerChange = !(values?.designerChecker == editItem?.designerChecker)

    let valuesList = [];        

    // console.log("Designers to unassign", selectedOptions);

    if(selectedOptions?.length > 1)//Multiple edition
    {          
      valuesList = selectedOptions.flatMap(value => {
        const employeeTitleId = evaluateAssignation(multipleAssignation);
        
        if (employeeTitleId === 3) { //Assignation by detailer and checker
          return [
            {
              orderDetailId: value.orderDetailId,
              employeeEmail: accounts[0].username,
              employeeTitleId: 1,
              hoursActual: 0,
              author: truncateEmail(accounts[0].username, 50),
              isActive: 1,
              userRole: employeeRole,
              employeeTitleRelId: getEmployeeTitleRelId(1),
            },
            {
              orderDetailId: value.orderDetailId,
              employeeEmail: accounts[0].username,
              employeeTitleId: 2,
              hoursActual: 0,
              author: truncateEmail(accounts[0].username, 50),
              isActive: 1,
              userRole: employeeRole,
              employeeTitleRelId: getEmployeeTitleRelId(2),
            }
          ];
        } else {
          return {
            orderDetailId: value.orderDetailId,
            employeeEmail: accounts[0].username,
            employeeTitleId: employeeTitleId,
            hoursActual: 0,
            author: truncateEmail(accounts[0].username, 50),
            isActive: 1,
            userRole: employeeRole,
            employeeTitleRelId: getEmployeeTitleRelId(employeeTitleId),
          };
        }
      });
    }        
    else //Single edition
    {
      let mappedValues = {
        orderDetailId: values?.orderDetailId,
        employeeEmail: accounts[0].username,
        employeeTitleId: 1, // designerType
        hoursActual: 0,
        author: truncateEmail(accounts[0].username,50),
        isActive: 1,
        userRole: employeeRole,
        employeeTitleRelId: getEmployeeTitleRelId(1),
      };

      valuesList = [mappedValues];
    }

    if(chipsRemoved && (chipsRemoved?.designerChecker?.length > 0 || chipsRemoved?.designerDetail?.length))
    {                         
      
      handleMultipleUnassignment();                      
    }

    if(isUnassign){
      setShowIsUnassign(true);
      setIsUnassign(false);
      return;
    }
    
    setShowIsUnassign(false);
    //multiple assignation process
    if(selectedOptions?.length > 1) handleMultipleAssignment(valuesList, evaluateAssignation(multipleAssignation));
    else //normal assignation
    try {
      if (token && notShow) {          

        //Only allow status change if user is assigned as designer for detail
        if(values.esoistatusDesc !== editItem.esoistatusDesc)
        {
          if(values.designerDetail === userName && editItem.designerDetail === userName)
            handleChange(values.esoistatusDesc);
          else      
            handleAssignOrder('Cannot change status for orders that are not assigned to yourself.', 'error', false);                
        }

        if(values.designerDetail !== "Not Assigned" && values.designerDetail === userName && editItem.designerDetail === "Not Assigned")
        { 
          valuesList[0].employeeTitleId = 1;        
          valuesList[0].hoursActual = values.orderNumber;   
          valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(1);       
          
          try {
            const response = await axios.post(
              `orderAssignation/`,            
               valuesList,            
              {
                headers: {
                  Authorization: token,
                },
              }
            );
            // console.log(response);
            // setResponseData(response?.data);

            if(response?.data?.alreadyAssignedOrders[0])
            {
              downloadFile(response.data.alreadyAssignedOrders[0].imageURL);
              setName(response.data.alreadyAssignedOrders[0].errorMessage);
              setModalVisible(true);
            }
            else if (response?.data?.successfullyAssignedOrders[0] === values.orderDetailId) {
              if(values.itemType === 'SPC') //Update status in detailer assign
              {
                const orderStatus= values.esoistatusDesc === 'Ready for Drafting' ? 3 : 1;

                handleStatusChange(orderStatus);
              }
              

              showModal = true;
          } else showModal = false;
          } 
          catch (error) {                                
            handleAssignError(error);
          };       
        }
        //handle update Est and Actual
        if(values.designerDetail !== "Not Assigned" && values.designerDetail === userName && editItem.designerDetail === userName)
        {          
            try {
              if (editItem.orderDetailId) {        
                valuesList[0].employeeTitleId = 1;        
                valuesList[0].hoursActual = values.orderNumber;   
                valuesList[0].isActive = 1;
                valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(1);
                
                const response = await axios.post(
                  `orderAssignation/`,            
                   valuesList,            
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                );
                if (response?.data?.successfullyAssignedOrders[0] === values.orderDetailId) {             
                  handleAssignOrder('Order updated successfully', '', false);
                }
            }
          } 
            catch (error) {
              handleAssignError(error);
            };                           
        }

        // Handle designerDetail UNassign
        else if ((values.designerDetail === 'Not Assigned' && designerDetailChange && values.esoistatusDesc !== "Released") ||
                    (values.designerDetail === 'Not Assigned' && designerDetailChange && values.esoistatusDesc !== "Released" && employeeRole === 3))
        {
          try {
            if (editItem.orderDetailId) {        
              valuesList[0].employeeTitleId = 1;        
              valuesList[0].hoursActual = 0;   
              valuesList[0].isActive = 0;
              valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(1);
              
              const response = await axios.post(
                `orderAssignation/`,            
                  valuesList,            
                {
                  headers: {
                    Authorization: token,
                  },
                }
              );
              // console.log(response);
              // setResponseData(response?.data);

              if (response?.data?.removedAssignments[0] === values.orderDetailId) {                             
              if(values.itemType === 'SPC') //Update status to the previous known
              {
                  const orderStatus= values.esoistatusDesc === 'Order Processing' ? 2 : 8;
                  if(values.designerDetail ==='Not Assigned' && editItem.designerDetail === userName) //Only allow status change if current user is assigned as designer for detail
                  handleStatusChange(orderStatus);
                }
                if(editItem.designerDetail === 'Not Assigned')
                handleUnassignOrder();                
              }
          }
        } 
          catch (error) {
            handleAssignError(error);            
          };                 
        }
        
        // Handle designerChecker assign
        if(values.designerChecker !== 'Not Assigned' && values.designerChecker === userName && editItem.designerChecker === "Not Assigned")
        {
          valuesList[0].employeeTitleId = 2;        
          valuesList[0].hoursActual = values.orderNumberChecker;
          valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(2);

          try {
            const response = await axios.post(
              `orderAssignation/`,            
               valuesList,            
              {
                headers: {
                  Authorization: token,
                },
              }
            );
            // console.log(response);
            // setResponseData(response?.data);

            if(response?.data?.alreadyAssignedOrders[0])
            {
              downloadFile(response.data.alreadyAssignedOrders[0].imageURL);
              setName(response.data.alreadyAssignedOrders[0].errorMessage);
              setModalVisible(true);
            }
              else if (response?.data?.successfullyAssignedOrders[0] === values.orderDetailId) {
              showModal = true;              
            } 
              else showModal = false;
          }
          catch (error) {            
            handleAssignError(error);                                   
          };    
        }
        //handle checker Est and Actual
        if(values.designerChecker !== "Not Assigned" && values.designerChecker === userName && editItem.designerChecker === userName)
          {
              try {
                if (editItem.orderDetailId) {        
                  valuesList[0].employeeTitleId = 2;        
                  valuesList[0].hoursActual = values.orderNumberChecker;   
                  valuesList[0].isActive = 1;
                  valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(2);
                  
                  const response = await axios.post(
                    `orderAssignation/`,            
                      valuesList,            
                    {
                      headers: {
                        Authorization: token,
                      },
                    }
                  );
                  if (response?.data?.successfullyAssignedOrders[0] === values.orderDetailId) {                       
                    handleAssignOrder('Order updated successfully', '', false);
                  }
              }
            } 
              catch (error) {
                handleAssignError(error);
              };                 
            
          }
        // Handle designerChecker UNassign
        else if ((values.designerChecker === 'Not Assigned' && designerCheckerChange && values.esoistatusDesc !== "Released") ||                    
                    (values.designerChecker === 'Not Assigned' && designerCheckerChange && values.esoistatusDesc !== "Released" && employeeRole===3) )
        {
          try {
            if (values.orderDetailId) {        
              valuesList[0].employeeTitleId = 2;        
              valuesList[0].hoursActual = 0;   
              valuesList[0].isActive = 0;
              valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(2);
              
              const response = await axios.post(
                `orderAssignation/`,            
                  valuesList,            
                {
                  headers: {
                    Authorization: token,
                  },
                }
              );
              // console.log(response);
              // setResponseData(response?.data);

              if (response?.data?.removedAssignments[0] === values.orderDetailId) {    
                handleUnassignOrder();                
              }
          }
        } 
          catch (error) {                        
            handleAssignError(error);

          };                 
        }

        //Validate changes allowed and show corresponding message
        if(values.itemType !== "FIN" && employeeRole !==3)
        {
          if((values.designerDetail !== userName && values.orderNumber !== editItem.orderNumber) 
            || (values.designerChecker !== userName && values.orderNumberChecker !== editItem.orderNumberChecker)
              || (editItem.designerChecker !== userName && values.designerChecker === 'Not Assigned' && values.designerChecker !== editItem.designerChecker)
                || (editItem.designerDetail !== userName && values.designerDetail === 'Not Assigned' && values.designerDetail !== editItem.designerDetail) 
          )
          {
            handleAssignOrder('Cannot change orders that are not assigned to yourself.', 'error', false);                  
          }
        }
        else if( employeeRole !==3)
        {
          if((values.designerDetail !== userName && values.orderNumber !== editItem.orderNumber) 
                || (editItem.designerDetail !== userName && values.designerDetail === 'Not Assigned' && values.designerDetail !== editItem.designerDetail) 
          )
          {
            handleAssignOrder('Cannot change orders that are not assigned to yourself.', 'error', false);                  
          }
          
        }
        if(showModal)
        {
            handleAssignOrder('Order updated successfully', '', false);
        }          
        fetchData();
    }
    } catch (error) {console.error('Error:', error);};

    setOpenForm(false);
  }

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const MyCustomLockedCell = (props) => (
    <CustomLockedCell {...props} enterEdit={enterEdit} />
  );

  const MyChangeOrderCell = (props) => (
    <ChangeOrderCell {...props} enterEdit={enterEdit} />
  );

  const MyCodeSplitCell = (props) => (
    <CodeSplitCell {...props} enterEdit={enterEdit} />
  );

  const handleSortChange = (e) => {
    setSort(e.sort || []);
  };

  const handleUpdateData = (dataItem, updatedData) => {
    // Encuentra el índice de dataItem en tu arreglo de datos
    const dataIndex = data.findIndex((item) => item.id === dataItem.id);

    // Actualiza el estado de los datos utilizando el índice encontrado
    setData((prevData) => {
      const newData = [...prevData];
      newData[dataIndex] = updatedData;
      return newData;
    });
  };

  const handleModalError = async (image, userName) => {
    downloadFile(image);
    setName(userName);
    setModalVisible(true);

    await fetchData();
  };

  // Zoom adjustment
  const [zoomLevel, setZoomLevel] = useState(1);
  const [gridHeight, setGridHeight] = useState(window.innerHeight - 270);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const windowWidth = window.outerWidth;
      const newZoomLevel = windowWidth / viewportWidth;
      setZoomLevel(newZoomLevel);

      const newGridHeight = window.innerHeight - 270;
      setGridHeight(newGridHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Zoom adjustment

  // Handle options selected in the selection column
  const [dataState, setDataState] = useState([]);
  useEffect(() => {
    if (users) {
      if(users.length > 0)
      {
        const updatedDataState = users.map((dataItem) =>
          Object.assign(
            {
              selected: false,
            },
            dataItem
          )
        );
        setDataState(updatedDataState);
      }
      else
      {
        setDataState([]);
      }
    }
    
  }, [users]);

  const [selectedState, setSelectedState] = useState({});
  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  useEffect(() => {
    const selectedItems = dataState.filter(item => selectedState[item[DATA_ITEM_KEY]]); 
    setSelectedOptions(selectedItems); //selectedItems stores the selected options information
    setEditItem(selectedItems[0]); //set ediItem for handleSubmitOrderAssignation
  }, [selectedState, dataState]);
  // Handle options selected in the selection column

  // Update the grid when assigning  
  const evaluateAssignation = (multipleAssignation) => {
    if (multipleAssignation.includes("Assign to me as Designer for detailing") && multipleAssignation.includes("Assign to me as a Designer for checking")) {
      return 3;
    } else if (multipleAssignation.includes("Assign to me as Designer for detailing")) {
      return 1;
    } else if (multipleAssignation.includes("Assign to me as a Designer for checking")) {
      return 2;
    } else {
      return 0; 
    }
  };

  const updatedOptions = (selectedAssignation) => {
    if (selectedAssignation == 3) {
      return selectedOptions.map(option => ({
              ...option,
              designerDetail: accounts[0].name,
              designerChecker: accounts[0].name
            })); 
    } else if (selectedAssignation == 1) {
      return selectedOptions.map(option => ({
              ...option,
              designerDetail: accounts[0].name
            }));
    } else if (selectedAssignation == 2) {
      return selectedOptions.map(option => ({
              ...option,
              designerChecker: accounts[0].name
            }));
    } else {
      return selectedOptions; 
    }
  };

  const [numSelected, setNumSelected] = useState(0);
  const [multiAssignation, setMultiAssignation] = useState(false);
  const [showIsUnassign, setShowIsUnassign] = useState(false);

  useEffect(() => {
    setMultiAssignation(true);
    setNumSelected(selectedOptions?.length);
    handleSubmitOrderAssignation(updatedOptions(evaluateAssignation(multipleAssignation))[0]); //set values for handleSubmitOrderAssignation
    fetchData();
    setSelectedState({});
    setMultiAssignation(false);
    // showToastSuccess();
  }, [onDrafthoursEdit]);
  // Update the grid when assigning  

  return (
    <div style={{ position: 'relative' }}>  
      {loading && (
        <Loader></Loader>
      )}

      <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title='Oops! It looks like this task was already taken'
        message={designerName + ' took this task'}
        type='TAKENORDER'
        image= {profileImgAssigned}
      />
      <ModalAssignation
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title='Oops! It looks like this task was already taken'
        message={designerName + ' took this task'}
        type='TAKENORDER'
        responseData = {responseData}
        numSelected = {numSelected}
        isUnassign = {showIsUnassign}
      />

      {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <div style={{ marginBottom: '15px', position: 'relative', height: gridHeight}}>
      
        <Grid
          className='roboto-15-400'
          data={dataState.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: false,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          skip={page.skip}
          take={page.take}
          total={totalRows}
          pageable={{
            buttonCount: 4,
            type: "input",
            pageSizes: [50, 150, 200, totalRows].filter(num => num <= totalRows),
            pageSize: pageSize,
          }}
          onPageChange={pageChange}
          sortable={true}
          filterable={true}
          filter={filter}
          onFilterChange={handleFilterChange}
          resizable
          reorderable
          sort={sort}
          onSortChange={handleSortChange}
          style={{
            borderRadius: '8px',
            display: 'flex',
            margin: '15px',
            height: 'inherit'
          }}
        >
          <GridColumn
            filterable={false}
            editable={false}
            field={SELECTED_FIELD}
            width="43px"
            headerSelectionValue={
              dataState.length > 0 && dataState.findIndex((item) => !selectedState[idGetter(item)]) === -1
            }
          />
          <GridColumn
            className='roboto-15-400'
            reorderable={false}
            sortable={false}
            filterable={false}
            editable={false}
            field="rowIndex"
            title="#"
            width="40px"
          />
          {selectedColumns
            .filter(
              (column) => 
                column.visible &&
                !(column.field === 'phase' && getCurrentLine().ProdFamilyName !== DPSA_LINE)
            )
            .map((column) => {
              if (column.field === 'esoistatusDesc') {
                return (
                  <GridColumn
                    className='roboto-15-400'
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    width={column.minWidth}
                    filter={filterConfig[column.filterType]}
                    filterCell={filterConfig[column.filterType].filterCell}
                    cell={(props) => (
                      <CustomDropdownCell
                        {...props}
                        updateData={(updatedData) => handleSubmit({ userId: props.dataItem.userId, ...updatedData })}
                        disabled={(props.dataItem.designerDetail === 'Not Assigned' || props.dataItem.esoistatusDesc === 'Released') ? true : false}
                        onFetchData={fetchData}
                        OnStatusChangeError={handleAssignOrder}
                        employeeRole={employeeRole}
                        employeeData={employeeData}
                      />
                    )}
                  />
                );
              } else if (column.field === 'designerChecker' || column.field === 'designerDetail') {
                return (
                  <GridColumn
                    className='roboto-15-400'
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    width={column.minWidth}
                    filter={filterConfig[column.filterType]}
                    filterCell={filterConfig[column.filterType].filterCell}
                    cell={(props) => {
                      if ((props.dataItem.itemType === 'FIN' && column.field === 'designerDetail') || (props.dataItem.itemType !== 'FIN')) { 
                        return (
                          <AssignButton
                            {...props}
                            updateData={(updatedData) => handleSubmit({ userId: props.dataItem.userId, ...updatedData })}
                            token={token}
                            onAssignOrder={handleAssignOrder}
                            onUnassignOrder={handleUnassignOrder}
                            onErrorAssign={handleAssignError}
                            modalError={handleModalError}
                            onFetchData={fetchData}
                            disabled={false}
                            employeeRole={employeeRole}
                            employeeData={employeeData}
                          />
                        );
                      } else { //do not show button if it is standard and checker
                        return <td style={{ width: '100%', height: '100%' }}></td>;
                      }
                    }}
                  />
                );
              } else if (column.field === 'mgmt') {
                  return (
                  <GridColumn
                    className='roboto-15-400'
                    sortable={true}
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    headerCell={column.header}
                    width={column.minWidth}
                    filter={filterConfig[column.filterType]}
                    format="{0:d}"
                    filterCell={filterConfig[column.filterType].filterCell}
                    cell ={MyChangeOrderCell}
                  />
                  );
                } else if (column.field === 'code') {
                  return (
                  <GridColumn
                    className='roboto-15-400'
                    sortable={true}
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    headerCell={column.header}
                    width={column.minWidth}
                    filter={filterConfig[column.filterType]}
                    format="{0:d}"
                    filterCell={filterConfig[column.filterType].filterCell}
                    cell ={MyCodeSplitCell}
                  />
                  );
              } else if (column.field === 'etoproc') {
                return (
                <GridColumn
                  className='roboto-15-400'
                  sortable={true}
                  key={column.field}
                  field={column.field}
                  title={column.title}
                  headerCell={column.header}
                  width={column.minWidth}
                  filter={filterConfig[column.filterType]}
                  format="{0:d}"
                  filterCell={filterConfig[column.filterType].filterCell}
                  cell={(props) => {
                    if ((props.dataItem.itemType !== 'FIN' && column.field === 'etoproc') ) { 
                      return (
                        column.cell(props)
                      );
                    } else { 
                      return <td style={{ width: '100%', height: '100%' }}></td>;
                    }
                  }}
                />
                );
              } else if (column.field === 'sellingPrice') {
                return (
                  <GridColumn
                  className='roboto-15-400'
                  sortable={true}
                  key={column.field}
                  field={column.field}
                  title={column.title}
                  headerCell={column.header}
                  width={column.minWidth}
                  filter="numeric"  
                  format="{0:n2}"
                  filterCell={filterConfig[column.filterType].filterCell}
                  cell={column.cell}
                />
                );
              }
              else if (column.field === 'phase') {
                return (
                  <GridColumn
                    className='roboto-15-400'
                    sortable={false}
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    headerCell={column.header}
                    width={column.minWidth}
                    filter={filterConfig[column.filterType]}
                    filterable={false}
                    filterCell={filterConfig[column.filterType].filterCell}
                    cell={column.cell}
                  />
                  );
              }                   
              else {
                  return (
                  <GridColumn
                    className='roboto-15-400'
                    sortable={true}
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    headerCell={column.header}
                    width={column.minWidth}
                    filter={filterConfig[column.filterType]}
                    format="{0:d}"
                    filterCell={filterConfig[column.filterType].filterCell}
                    cell={column.cell}
                  />
                  );
                }
              })}
              
          <GridColumn
            filterable={false}
            editable={false}
            width="40px"
            locked={true}
            cell={MyCustomLockedCell}
          />
        </Grid>
      </div>

      {openForm && editItem && editItem.orderDetailId && (
      <SidePanel
        cancelEdit={handleCancelEdit}
        onSubmit={handleSubmitOrderAssignation}
        item={editItem}
        onPriorityUpdate={handlePriorityUpdate}
        onDesignerCheckerHrsChange={handleDesignerCheckerHrsChange}
        onDesignerDetailHrsChange={handleDesignerDetailHrsChange}
        disabled={false}
      />
    )}
    </div>
  );
}

export default GeneralGrid;