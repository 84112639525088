import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from '../axiosConfig';
import useProfileData from '../services/ProfileDataService';
import {ListView} from "@progress/kendo-react-listview";
import Toast from './ToastNotification';
import { useMsal } from "@azure/msal-react";
import { SvgIcon } from "@progress/kendo-react-common";
import { circleIcon, searchIcon, xIcon} from "@progress/kendo-svg-icons";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import columnFieldMapping from '../Utilities/columnFieldMapping'
import { getCurrentLine, subscribeToCurrentLineChanges } from '../services/CurrentLineService';
import './customizedFilters/CustomFilter.css';
import LogOutService from '../services/LogOutService';
import { truncateEmail } from '../Utilities/utils';
import SidePanel from './SidePanel';
import './SidePanel.css';
import { formatDate } from '../Utilities/utils';
import { Loader } from 'rsuite';
import { InputPrefix, TextBox } from "@progress/kendo-react-inputs";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import {ChipsMAW, DateMAW, RadioButtonMAW } from './CustomFormComponents';
import CheckboxMAW from '../components/CheckboxMaw';
import dayjs from 'dayjs';
import useDebounce from '../Utilities/useDebounce';
import useEmployeeRole from '../Utilities/EmployeeRole';

const checkmark = (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.51875 8.49994L0.71875 4.69994L1.66875 3.74994L4.51875 6.59994L10.6354 0.483276L11.5854 1.43328L4.51875 8.49994Z" fill="white"/>
  </svg>
);


const FiltersMAW = ({fetchData, filterOrders, startFilter, actualHours, estimateHours}) => {
  const [filters, setFilters] = useState([]);
  const [filtersFormated, setFiltersFormated] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const [endDate2, setEndDate2] = useState(null);
  const [startDate3, setStartDate3] = useState(null);
  const [endDate3, setEndDate3] = useState(null);

  const [emptyDate, setEmptyDate] = useState(startDate !== null || startDate2 !== null || startDate3 !== null);
  const dateFields = ["buildWeek", "draftingStartDate", "draftingDueDate"];
  const [orderBy, setOrderBy] = useState('asc');

  const updateFilters = useCallback( (field, value) => {        
    setFilters((prevFilters) => {      

      let newFilters = prevFilters.filter((filter) => filter.field !== field);
      let sort = orderBy;
      
      // split options
      if(field !== 'orderBy' && !dateFields.includes(field) && field !== 'priority')
      {

        if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== 'All') { // Do not add to filter if option is all
              newFilters.push({ field, operator: 'contains', value: val });
            }
          });
        } else {

          if (value !== 'All') { 
            newFilters.push({ field, operator: 'contains', value });
          }
        }
      }
      else if(dateFields.includes(field)) 
      {

        newFilters.push({ field, operator: 'gte', value: value.startDate });
        newFilters.push({ field, operator: 'lte', value: value.endDate });
      }
      else if(field === 'priority')
        {

          newFilters = newFilters.filter((filter) => filter.field !== 'draftingDueDate');
          // const today = new Date();
          // const twoWeeksBefore = new Date();
          // twoWeeksBefore.setDate(today.getDate() - 14); 

          const today = new Date();
          const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay())); 
          const twoWeeksBefore = new Date(startOfWeek);
          twoWeeksBefore.setDate(startOfWeek.getDate() - 14);

          switch (value)
          {
            case 'Past due date':
              newFilters.push({ field: 'draftingDueDate', operator: 'lt', value: today});              
              break;
            case 'Near to past due date':
              newFilters.push({ field: 'draftingDueDate', operator: 'gte', value: twoWeeksBefore}); 
              newFilters.push({ field: 'draftingDueDate', operator: 'lte', value: today}); 
              break;
            case 'Far from due date': 
              newFilters.push({ field: 'draftingDueDate', operator: 'lte', value: twoWeeksBefore}); 
              break;

          }          
        }
      else{
        if(value === "Newer due date first")
        {          
          sort = 'desc';
          setOrderBy(sort);
        }
        else if(value === "Older due date first")
        {                    
          sort = 'asc';
          setOrderBy(sort)
        }                      
      }

      
      const filtersWithLogic = {
        logic: 'or',
        filters: newFilters
      };                      
                  
      setFiltersFormated(filtersWithLogic);
      return newFilters;
    
    });    
  }, [startFilter, filterOrders]);

  useEffect(() => {    
    fetchData(null, filtersFormated, orderBy);    
  }, [filtersFormated]);


  const fixedValues = [{
    "columnName": "orderBy",
    "uniqueValues": [
      "Older due date first",  
      "Newer due date first"
        
    ]
  },
  {
      "columnName": "priority",
      "uniqueValues": [
          "Past due date",
          "Near to past due date",
          "Far from due date"
      ]
  }]

  const [value, setValue] = useState(actualHours);
  const min = 0;
  const max = estimateHours;
  useEffect(() => {
    setValue(actualHours);
    //console.log('actualHours', actualHours, 'estimateHours', estimateHours)
  }, [actualHours, estimateHours]);

  return (
    <div style={{ background: '#FFF', borderRadius: '5px', height: 'inherit', width: '340px', filter: 'drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.09))' }}>
      <div className='roboto-17-500' style={{ margin: '16px' }}>Progress</div>
      <div  className='roboto-15-400' style={{width: '100%', borderBottom: '1px solid #CFDFDF', justifyContent: 'center', display: 'flex', padding: '0px 16px', flexDirection: 'column'}}>
        <ProgressBar
          // label={'none'} 
          min={min} max={max} 
          value={value} 
          style={{width: '308px'}}/>
        <div className='roboto-15-500' style={{margin: '8px 0px 16px 0px', justifyContent: 'flex-start', display: 'flex'}}>{actualHours}{' of '}{estimateHours}{'hrs'}</div>
      </div>
      <div className='roboto-17-500' style={{ margin: '16px' }}>Filters</div>
      <RadioButtonMAW
        label='Order by'
        endPointData={fixedValues}
        field='orderBy'
        onChangeFilter={updateFilters}
      />
      <ChipsMAW
        label='Status'        
        fetchOptions={true}
        field='esoistatusDesc'
        onChangeFilter={updateFilters}
      />
      <ChipsMAW
        label='Priority'
        endPointData={fixedValues}
        fetchOptions={false}
        field='priority'
        onChangeFilter={updateFilters}
      />
      <CheckboxMAW
        label='GO Status'        
        field='gostatus'        
        fetchOptions={false}
        onChangeFilter={updateFilters}
      />
      <CheckboxMAW
        label='Order Type'        
        field='orderType'        
        fetchOptions={false}
        onChangeFilter={updateFilters}
      />
      <DateMAW
        onChangeFilter={updateFilters}
        emptyDate={emptyDate}
        setEmptyDate={setEmptyDate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        label='Build week'
        field='buildWeek'
      />
      <DateMAW
        onChangeFilter={updateFilters}
        emptyDate={emptyDate}
        setEmptyDate={setEmptyDate}
        startDate={startDate2}
        setStartDate={setStartDate2}
        endDate={endDate2}
        setEndDate={setEndDate2}
        label='Drafting due date'
        field='draftingDueDate'
      />
      <DateMAW
        onChangeFilter={updateFilters}
        emptyDate={emptyDate}
        setEmptyDate={setEmptyDate}
        startDate={startDate3}
        setStartDate={setStartDate3}
        endDate={endDate3}
        setEndDate={setEndDate3}
        label='Drafting start date'
        field='draftingStartDate'
      />
    </div>
  );
};


const StatusField = ({ item, type, onChange, value }) => {

  // const tooltip = React.useRef(null);
  function validateStatusChange(selectedStatus) {
    //Disable if order has not being assigned
    if (item.esoiStatusDesc === 'Released' || item.designerDetail === 'Not Assigned') {
      return false;
    }

    if(type === 'SPC')
    {
      const statusOrder = [
        'Not started',
        'Order Processing',
        'Ready for Drafting',
        'WIP',
        'Ready for Check',
        'Submitted',
        'Released'
      ];

      const currentIndex = statusOrder.indexOf(item.esoistatusDesc);
      const selectedIndex = statusOrder.indexOf(selectedStatus);

      if (selectedIndex === -1 || selectedIndex !== currentIndex + 1) {
        //if next status is not correct return -1
        return false;
      }
      return true;
    } else {
      if (item.esoistatusDesc === 'Released') {
        return false;
      } else {
        return true;
      }
    }

  }

  return (
    <div>
      <div className='roboto-15-700' style={{ textAlign: 'left'}}>Status: </div>
      <div
        style={{ display: 'inline-flex', gap: '8px', flexDirection: 'row', flexWrap: 'wrap', marginRight:'16px'}}
        // onMouseOver={(event) =>
        //   tooltip.current && tooltip.current.handleMouseOver(event)
        // }
        // onMouseOut={(event) =>
        //   tooltip.current && tooltip.current.handleMouseOut(event)
        // }
      >
      <button
        title="Not Started"
        type={"button"}
        // disabled={true}
        disabled={!(item.esoistatusDesc === "Not Started")}
        onClick={() => onChange({ value: "Not Started" })}
        style={{cursor: item.esoistatusDesc === "Not Started" ? 'not-allowed' :  'default' }}
        className={`roboto-15-400
        ${item.esoistatusDesc === "Not Started" ? 'ns-button' : 'bar-button blue-text'}`}
      >
        Not Started
      </button>
      {type === 'SPC' && (
        <button
          title="Order Processing"
          type={"button"}
          disabled={!validateStatusChange('Order Processing') && !(item.esoistatusDesc === "Order Processing")}
          onClick={() => onChange({ value: "Order Processing" })}
          style={{cursor: item.esoistatusDesc === "Order Processing" ? 'not-allowed' : !validateStatusChange('Order Processing') ? 'default' : 'pointer'}}
          className={`roboto-15-400
          ${item.esoistatusDesc === "Order Processing" ? 'op-button' : 'bar-button blue-text'}`}
        >
          Order Processing
        </button>
      )}

      {type === 'SPC' && (
      <div style={{ display: 'inline-flex', gap: '8px', flexDirection: 'row', flexWrap: 'wrap'}}>
        <button
          title="Ready for Drafting"
          type={"button"}
          disabled={!validateStatusChange('Ready for Drafting') && !(item.esoistatusDesc === "Ready for Drafting")}
          onClick={() => onChange({ value: "Ready for Drafting" })}
          style={{cursor: item.esoistatusDesc === "Ready for Drafting" ? 'not-allowed' : !validateStatusChange('Ready for Drafting') ? 'default' : 'pointer'}}
          className={`roboto-15-400
          ${item.esoistatusDesc === "Ready for Drafting" ? 'RFD-button' : 'bar-button blue-text'}`}
        >
          Ready for Drafting
        </button>
        <button
          title="Work in progress"
          type={"button"}
          disabled={!validateStatusChange('WIP') && !(item.esoistatusDesc === "WIP")}
          onClick={() => onChange({ value: "WIP" })}
          style={{cursor: item.esoistatusDesc === "WIP" ? 'not-allowed' : !validateStatusChange('WIP') ? 'default' : 'pointer'}}
          className={`roboto-15-400
          ${item.esoistatusDesc === "WIP" ? 'wip-button' : 'bar-button blue-text'}`}
        >
          WIP
        </button>
        <button
          title="Ready for Check"
          type={"button"}
          disabled={!validateStatusChange('Ready for Check') && !(item.esoistatusDesc === "Ready for Check")}
          onClick={() => onChange({ value: "Ready for Check" })}
          style={{cursor: item.esoistatusDesc === "Ready for Check" ? 'not-allowed' : !validateStatusChange('Ready for Check') ? 'default' : 'pointer'}}
          className={`roboto-15-400
          ${item.esoistatusDesc === "Ready for Check" ? 'RFCH-button text-white' : 'bar-button blue-text'}`}
        >
          Ready for Check
        </button>
      </div>
      )}
        <button
          title="Submitted"
          type={"button"}
          disabled={!validateStatusChange('Submitted') && !(item.esoistatusDesc === "Submitted")}
          onClick={() => onChange({ value: "Submitted" })}
          style={{cursor: item.esoistatusDesc === "Submitted" ? 'not-allowed' : !validateStatusChange('Submitted') ? 'default' : 'pointer'}}
          className={`roboto-15-400
          ${item.esoistatusDesc === "Submitted" ? 's-button' : 'bar-button blue-text'}`}
        >
          Submitted
        </button>
        <button
          title='Released'
          type={"button"}
          disabled={!validateStatusChange('Released') && !(item.esoistatusDesc === "Released")}
          onClick={() => onChange({ value: "Released" })}
          style={{cursor: item.esoistatusDesc === "Released" ? 'not-allowed' : !validateStatusChange('Released') ? 'default' : 'pointer'}}
          className={`roboto-15-400
          ${item.esoistatusDesc === "Released" ? 'r-button text-white' : 'bar-button blue-text'}`}
        >
          Released
        </button>
        {/* <Tooltip
          ref={tooltip}
          anchorElement="target"
          position="top"
        /> */}
      </div>
    </div>
  );
};


const MyWorkCard  = ({ itemExample, imageDetailerUrl, imageCheckerUrl, fetchData, loading }) => {

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    }
  ));

  let tooltipColor;
  let tooltipText;
  if (itemExample.itemType === 'SPC') {
    tooltipColor = "rgba(205, 184, 235)";
    tooltipText = `Special ${itemExample.orderType}`;
  } else {
    tooltipColor = "rgba(209, 243, 255)";
    tooltipText = `Standar ${itemExample.orderType}`;
  }

  const priorityIcon = [
    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="18" fill="#FF4949">
        <path d="M200-120v-680h360l16 80h224v400H520l-16-80H280v280h-80Zm300-440Zm86 160h134v-240H510l-16-80H280v240h290l16 80Z"/>
    </svg>
  ];

  const viewIcon = [
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 12.4517C9.9375 12.4517 10.7344 12.1235 11.3906 11.4673C12.0469 10.811 12.375 10.0142 12.375 9.07666C12.375 8.13916 12.0469 7.34229 11.3906 6.68604C10.7344 6.02979 9.9375 5.70166 9 5.70166C8.0625 5.70166 7.26562 6.02979 6.60938 6.68604C5.95312 7.34229 5.625 8.13916 5.625 9.07666C5.625 10.0142 5.95312 10.811 6.60938 11.4673C7.26562 12.1235 8.0625 12.4517 9 12.4517ZM9 11.1017C8.4375 11.1017 7.95938 10.9048 7.56563 10.511C7.17188 10.1173 6.975 9.63916 6.975 9.07666C6.975 8.51416 7.17188 8.03604 7.56563 7.64229C7.95938 7.24854 8.4375 7.05166 9 7.05166C9.5625 7.05166 10.0406 7.24854 10.4344 7.64229C10.8281 8.03604 11.025 8.51416 11.025 9.07666C11.025 9.63916 10.8281 10.1173 10.4344 10.511C10.0406 10.9048 9.5625 11.1017 9 11.1017ZM9 14.7017C7.175 14.7017 5.5125 14.1923 4.0125 13.1735C2.5125 12.1548 1.425 10.7892 0.75 9.07666C1.425 7.36416 2.5125 5.99854 4.0125 4.97979C5.5125 3.96104 7.175 3.45166 9 3.45166C10.825 3.45166 12.4875 3.96104 13.9875 4.97979C15.4875 5.99854 16.575 7.36416 17.25 9.07666C16.575 10.7892 15.4875 12.1548 13.9875 13.1735C12.4875 14.1923 10.825 14.7017 9 14.7017Z" fill="#0097E0"/>
    </svg>
  ];

  const editIcon = [
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1954 2.08756C17.1954 2.08756 18.29 0.992966 19.3846 2.08756C20.4791 3.18216 19.3846 4.27675 19.3846 4.27675L9.5332 14.1281H7.34401V11.9389L17.1954 2.08756ZM4.06028 4.27675V17.4119H17.1954V10.8443L19.3846 8.65513V17.4119V19.6011H17.1954H4.06028H1.87109V17.4119V4.27675V2.08756H4.06028H12.817L10.6279 4.27675H4.06028Z" fill="#0097E0"/>
    </svg>
  ];

  // SidePanel functionalities
  const [openForm, setOpenForm] = useState(false);
  const [estimateOption, setEstimateOption] = useState({ myAssignedWork: false, isChecker: false, isError: false });

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const { employeeRole, employeeData } = useEmployeeRole();
  const [editItem, setEditItem] = useState();
  const enterEdit  = (estimate, designerType, showErrorToast) => {
    const updatedItem = {
      ...itemExample,
      orderNumber: detailActHours ? detailActHours : 0,
      orderNumberChecker: checkActHours ? checkActHours : 0,
    };

    setOpenForm(true);
    setEditItem(updatedItem);

    if (estimate === true) {
      setEstimateOption(prevState => ({
        ...prevState,
        myAssignedWork: true,
        isChecker: designerType,
        isError: showErrorToast, //used to show notification when actual hrs <= 0
      }));
    } else {
      setEstimateOption(prevState => ({
        ...prevState,
        myAssignedWork: false,
        isChecker: false,
        isError: false,
      }));
    }
  };

  function getEmployeeTitleRelId(designerType) {      
    let employeeTitleRelIdValue;

    employeeData.forEach(item => {
        if (item.employeeTitleId === designerType) {
            employeeTitleRelIdValue = item.employeeTitleRelId;
        }
    });

    return employeeTitleRelIdValue; 
  }

  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const { accounts } = useMsal();
  const { instance } = useMsal();
  const { token } = useProfileData(false);

  const handleSubmitOrderAssignation = async (values) => {
    var showModal = false;

    const userName = accounts[0].name;
    // console.log('before', editItem.esoiStatusDesc)

    const designerDetailChange = !(values.designerDetail == editItem.designerDetail)
    const designerCheckerChange = !(values.designerChecker == editItem.designerChecker)

    let valuesList = [];

    let mappedValues = {
      orderDetailId: values.orderDetailId,
      employeeEmail: accounts[0].username,
      employeeTitleId: 1,
      hoursActual: 0,
      author: truncateEmail(accounts[0].username,50),
      isActive: 1,
      userRole: employeeRole,
      employeeTitleRelId: getEmployeeTitleRelId(1),
    };

    valuesList = [mappedValues];

    try {
      if (token) {

        //Only allow status change if user is assigned as designer for detail
        if(values.esoistatusDesc !== editItem.esoistatusDesc)
        {
          if(values.designerDetail === userName && editItem.designerDetail === userName)
            handleChange(values.esoistatusDesc);
          else
            handleAssignOrder('Cannot change status for orders that are not assigned to yourself.', 'error');
        }

        if(values.designerDetail !== "Not Assigned" && values.designerDetail === userName && editItem.designerDetail === "Not Assigned")
        {
          valuesList[0].employeeTitleId = 1;
          valuesList[0].hoursActual = values.orderNumber;
          valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(1); 

          try {
            const response = await axios.post(
              `orderAssignation/`,
               valuesList,
              {
                headers: {
                  Authorization: token,
                },
              }
            );

          if(response?.data?.alreadyAssignedOrders[0])
          {
            downloadFile(response.data.alreadyAssignedOrders[0].imageURL);
            setName(response.data.alreadyAssignedOrders[0].errorMessage);
            setModalVisible(true);
          }
          else if (response?.data?.successfullyAssignedOrders[0] === values.orderDetailId) {
            if(values.itemType === 'SPC') //Update status in detailer assign
            {
              const orderStatus= values.esoistatusDesc === 'Ready for Drafting' ? 3 : 1;

              handleStatusChange(orderStatus);
            }


            showModal = true;
            // console.log('Handle designerDetail assign')
          } else showModal = false;
          }
          catch (error) {

            handleAssignError(error);

          };
        }
        //handle update Est and Actual
        if(values.designerDetail !== "Not Assigned" && values.designerDetail === userName && editItem.designerDetail === userName)
        {
            try {
              if (editItem.orderDetailId) {
                valuesList[0].employeeTitleId = 1;
                valuesList[0].hoursActual = values.orderNumber;
                valuesList[0].isActive = 1;
                valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(1);

                const response = await axios.post(
                  `orderAssignation/`,
                   valuesList,
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                );
                if (response?.data?.successfullyAssignedOrders[0] === values.orderDetailId) {
                  handleAssignOrder('Order updated successfully');
                }
            }
          }
            catch (error) {
              handleAssignError(error);
            };
        }

        // Handle designerDetail UNassign
        else if (values.designerDetail === 'Not Assigned' && designerDetailChange && values.esoistatusDesc !== "Released")
        {
          try {
            if (editItem.orderDetailId) {
              valuesList[0].employeeTitleId = 1;
              valuesList[0].hoursActual = 0;
              valuesList[0].isActive = 0;
              valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(1);

              const response = await axios.post(
                `orderAssignation/`,
                  valuesList,
                {
                  headers: {
                    Authorization: token,
                  },
                }
              );
              if (response?.data?.removedAssignments[0] === values.orderDetailId) {

              if(values.itemType === 'SPC') //Update status to the previous known
              {
                  const orderStatus= values.esoistatusDesc === 'Order Processing' ? 2 : 8;
                  if(values.designerDetail ==='Not Assigned' && editItem.designerDetail === userName) //Only allow status change if current user is assigned as designer for detail
                  handleStatusChange(orderStatus);
                }
                if(editItem.designerDetail === 'Not Assigned')
                handleUnassignOrder();
              }
          }
        }
          catch (error) {
            handleAssignError(error);
          };
        }

        // Handle designerChecker assign
        if(values.designerChecker !== 'Not Assigned' && values.designerChecker === userName && editItem.designerChecker === "Not Assigned")
        {

          valuesList[0].employeeTitleId = 2;
          valuesList[0].hoursActual = values.orderNumberChecker;
          valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(2);

          try {
            const response = await axios.post(
              `orderAssignation/`,
               valuesList,
              {
                headers: {
                  Authorization: token,
                },
              }
            );
            if(response?.data?.alreadyAssignedOrders[0])
            {
              downloadFile(response.data.alreadyAssignedOrders[0].imageURL);
              setName(response.data.alreadyAssignedOrders[0].errorMessage);
              setModalVisible(true);
            }
            else if (response?.data?.successfullyAssignedOrders[0] === values.orderDetailId) {
              showModal = true;
            }
              else showModal = false;
          }
          catch (error) {

            handleAssignError(error);

          };
        }
        //handle checker Est and Actual
        if(values.designerChecker !== "Not Assigned" && values.designerChecker === userName && editItem.designerChecker === userName)
          {
            // console.log(editItem);
              try {
                if (editItem.orderDetailId) {
                  valuesList[0].employeeTitleId = 2;
                  valuesList[0].hoursActual = values.orderNumberChecker;
                  valuesList[0].isActive = 1;
                  valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(2);

                  const response = await axios.post(
                    `orderAssignation/`,
                      valuesList,
                    {
                      headers: {
                        Authorization: token,
                      },
                    }
                  );
                  if (response?.data?.successfullyAssignedOrders[0] === values.orderDetailId) {
                    handleAssignOrder('Order updated successfully');
                  }
              }
            }
              catch (error) {
                handleAssignError(error);
              };

          }
        // Handle designerChecker UNassign
        else if (values.designerChecker === 'Not Assigned' && designerCheckerChange && values.esoistatusDesc !== "Released")
        {
          try {
            if (values.orderDetailId) {
              valuesList[0].employeeTitleId = 2;
              valuesList[0].hoursActual = 0;
              valuesList[0].isActive = 0;
              valuesList[0].employeeTitleRelId = getEmployeeTitleRelId(2);

              const response = await axios.post(
                `orderAssignation/`,
                  valuesList,
                {
                  headers: {
                    Authorization: token,
                  },
                }
              );
              if (response?.data?.removedAssignments[0] === values.orderDetailId) {
                handleUnassignOrder();
              }
          }
        }
          catch (error) {
            handleAssignError(error);

          };
        }

        if(showModal) handleAssignOrder();
        fetchData();
    }
    } catch (error) {console.error('Error:', error);};

    setOpenForm(false);
  }

  const [detailActHours, setDetailActHours] = useState(0);
  const [checkActHours, setCheckActHours] = useState(0);

  useEffect(() => {
    const fetchHrssData = async () => {
      if (token) {
        try {

          const response = await axios.get(`/getOrderAssignation/${itemExample.orderDetailId}`, {
            headers: {
              Authorization: token,
            },
          });

          const detailInfo = ((response.data.filter(item => item.employeeTitleId === 1)));
          const checkInfo = ((response.data.filter(item => item.employeeTitleId === 2)));
          if (detailInfo.length > 0 ) {
            setDetailActHours(detailInfo[0].hoursActual);
          }

          if (checkInfo.length > 0) {
            setCheckActHours(checkInfo[0].hoursActual);
          }

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      else
      {
        console.error("Error fetching data:");
      }
    };

    fetchHrssData();
  }, [token, toast]);

  const handleChange = async (selectedStatus) => {

    const status = getSelectedStatusID(selectedStatus);
    try {
      const updateUrl = `/updateOrderStatus/${encodeURIComponent(editItem.orderDetailId)}?esoiStatusId=${encodeURIComponent(status)}&Author=${encodeURIComponent(truncateEmail(accounts[0].username,50))}`;

      const response = await axios.put(
        updateUrl,
        null,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200 && editItem.itemType === 'SPC') {
        if(status === 8 && (editItem.esoistatusDesc !== selectedStatus))
        {
          handleUnassignStatus();
        }

      }
      fetchData();
    }
    catch (error) {
      showToastError();
    };

  };

  const handleUnassignStatus = async () =>{

    let valuesList = [];

    let mappedValues = {
      orderDetailId: editItem.orderDetailId,
      employeeEmail: accounts[0].username,
      employeeTitleId: 1,
      hoursActual: 0,
      author: truncateEmail(accounts[0].username,50),
      isActive: 0,
      userRole: employeeRole,
      employeeTitleRelId: getEmployeeTitleRelId(1),
    };

    valuesList = [mappedValues];

    try {
        if (token && editItem.orderDetailId) {
          const response = await axios.post(
            `orderAssignation/`,
              valuesList,
            {
              headers: {
                Authorization: token,
              },
            }
          );
        }


      } catch (error) {
        showToastError();
      };
  };

  const handleDesignerDetailHrsChange = (newDesignerDetailHrs,) => {
    setEditItem((prevItem) => ({
      ...prevItem,
      orderNumber: newDesignerDetailHrs
    }));
  };

  const handleDesignerCheckerHrsChange = (newDesignerCheckerHrs) => {
    setEditItem((prevItem) => ({
      ...prevItem,
      orderNumberChecker: newDesignerCheckerHrs
    }));
  };

  const [profileImgAssigned, setProfileImgAssigned] = useState();
  const [designerName, setName] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const downloadFile = async (imageName) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    let errorMessage;
    if (token) {
      const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(imageName)}&departmentsFolder=${encodeURIComponent("")}&departmentFolder=${encodeURIComponent("")}&orderNumFolder=${""}&containerName=${encodeURIComponent("employeeprofileimages")}&isProfileImage=${encodeURIComponent(true)}`;
      try {
        const response = await axios.get(downloadUrl, {
          headers: {
            Authorization: token,
          },
          responseType: 'blob',
        });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const imageUrl = URL.createObjectURL(blob);

      const imageSrc = imageUrl;

      if (imageSrc == null) {
        setProfileImgAssigned('/DefaultProfile.png');
      }
      else {
        setProfileImgAssigned(imageSrc);
      }

      } catch (error) {
        if (error.response) {

          const status = error.response.status;
          const errorMessages = {
            401: 'Expired Token',
            500: 'The file does not exist on the blob storage'
          };

          errorMessage = errorMessages[status] || 'An error occurred';
        } else {
          errorMessage = error.message || 'An unexpected error occurred';
        }

        setToast({ showToast: true, message: errorMessage, type: 'error' });
      }
    }
  };

  const handleStatusChange = async (status) => {

    try {
      const updateUrl = `/updateOrderStatus/${encodeURIComponent(editItem.orderDetailId)}?esoiStatusId=${encodeURIComponent(status)}&Author=${encodeURIComponent(truncateEmail(accounts[0].username,50))}`;

      const response = await axios.put(
        updateUrl,
        null,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );
    }
    catch (error) {
      handleAssignError(error);
    };
  };

  const handlePriorityUpdate = async (unmarkSuccess) => {
    if (unmarkSuccess == true) {
      setIdtoast(idtoast + 1);
      setToast({ showToast: false });
      setToast({ showToast: true, message: 'Order unmarked successfully', type: 'success' });
    }
    await fetchData();
  };

  const handleAssignOrder = async (text, state) => {
    if(!text) text= 'Order assigned successfully'
    if(!state) state= 'success'

    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: text, type: state });

    await fetchData();
  };

  const handleUnassignOrder = async () => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Order unassigned successfully', type: 'success' });

    await fetchData();
  };

  const handleAssignError = async (error) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });

    if(error?.response?.data?.message)
      setToast({ showToast: true, message: error.response.data.message, type: 'error' });
    else
      setToast({ showToast: true, message: 'An error occurred while assigning the order', type: 'error' });
  };

  const showToastError = async () => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'An unexpected error occurred', type: 'error' });
  };
  // SidePanel functionalities

  function getSelectedStatusID(eValue) {
    switch (eValue) {
      case 'Order Processing':
        return 1;
      case 'Not started':
        return 2;
      case 'WIP':
        return 3;
      case 'Done':
        return 4;
      case 'Submitted':
        return 5;
      case 'Released':
        return 6;
      case 'Ready for Check':
        return 7;
      case 'Ready for Drafting':
        return 8;
      default:
        return 2;
    }
  }

  const today = new Date();
  const draftingDueDate = new Date(itemExample?.draftingDueDate); //year-month-day
  const differenceInMilliseconds = draftingDueDate - today;
  const millisecondsInOneDay = 24 * 60 * 60 * 1000;
  const daysRemaining = Math.ceil(differenceInMilliseconds / millisecondsInOneDay);


  const onAssignOrder = async (value) => {
    if(value.value === itemExample.esoistatusDesc)
    {
      return;
    }
      const userName = accounts[0].name;

      if(itemExample.designerDetail === userName )
      {
        const status = getSelectedStatusID(value.value);

        //validate estimated

        if(itemExample.itemType === 'SPC')
        {
          if(value.value === 'Ready for Check' && detailActHours <= 0)
          {
            enterEdit(true, true, true);
            return;
          }
          else if((value.value === 'Submitted' || value.value === 'Released') && checkActHours <= 0)
          {
            enterEdit(true, false, true);
            return;
          }
        }

        if (token) {
          try {
            const updateUrl = `/updateOrderStatus/${encodeURIComponent(itemExample.orderDetailId)}?esoiStatusId=${encodeURIComponent(status)}&Author=${encodeURIComponent(truncateEmail(accounts[0].username,50))}`;
            const response = await axios.put(
              updateUrl,
              null,
              {
                headers: {
                  Authorization: token,
                  'Content-Type': 'application/json',
                }
              }
            );

            if (response.status === 200) {

              if(status === 8)
              {
                handleUnassign();
              }

              fetchData();
            }
          }
          catch (error) {
            handleAssignOrder(error, 'error');
          };
        }
      }
      else
      {
        // OnStatusChangeError('Cannot change status for orders that are not assigned to yourself.', 'error');
        handleAssignOrder('Cannot change status for orders that are not assigned to yourself.', 'error');
      }
    }

  //Deassign detailer when change status to Ready for drafting
  const handleUnassign = async () =>{

    let valuesList = [];

    let mappedValues = {
      orderDetailId: itemExample.orderDetailId,
      employeeEmail: accounts[0].username,
      employeeTitleId: 1,
      hoursActual: 0,
      author: truncateEmail(accounts[0].username,50),
      isActive: 0,
      userRole: employeeRole,
      employeeTitleRelId: getEmployeeTitleRelId(1),
    };

    valuesList = [mappedValues];

    try {
        if (token && itemExample.orderDetailId) {
          const response = await axios.post(
            `orderAssignation/`,
              valuesList,
            {
              headers: {
                Authorization: token,
              },
            }
          );
        }

  } catch (error) {
    handleAssignOrder(error, 'error');
  };
};

  return (

    <div className='my-work-card roboto-15-400' style={{ marginBottom: '8px' }}>

    {loading ? (
        <Loader />
      ) : (
        <>
    {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      {loading && (
        <div className= 'loader' style={{ display: 'flex', justifyContent: 'center', color: '#0097E0', alignItems: 'center', width: '100%'}}>
          <Loader size="sm" center classPrefix= {'loader'} style={{ display: 'flex', justifyContent: 'center', color: '#0097E0' }} type={"infinite-spinner"} />
        </div>
      )}
      {!loading && (
      <div>
        <div style={{ display: 'flex', padding: '16px 16px 8px 14px', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
              <div className='roboto-19-700' style={{ fontSize: '17px', borderRadius: '5px', padding: '2px', background: itemExample.itemType === 'SPC' ? '#CDB8EB' : '#D1F3FF' }}>
                <BootstrapTooltip title={
                  <div className='roboto-15-400 text-light' style={{ alignItems: 'center', marginRight: '8px' }}>
                    <SvgIcon icon={circleIcon} size={'xlarge'} color= {tooltipColor} />{tooltipText}
                  </div> } placement="top">
                  ESOI #: {itemExample.esoi}
                </BootstrapTooltip>
              </div>
              <div style={{ padding: '2px', gap: '8px', display: 'flex', flexDirection: 'row' }}>
                <div className='roboto-17-700'><b>SO #: {itemExample.so}</b></div>
                <div style={{ display: itemExample.isPriority ? 'flex' : 'none' }}>{priorityIcon}</div>
              </div>
            </div>
            <StatusField item={itemExample} type={itemExample.itemType} onChange={onAssignOrder}/>
          </div>
          <button className='secondary-button' style={{ minWidth: '36px', padding: '0px' }}
            onClick={enterEdit}
            >{viewIcon}
          </button>
        </div>
        <div style={{ padding: '8px 16px', borderTop: '2px solid #0097E0', gap: '16px', display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
          <div>
            <b>Drafting start/due: </b>{formatDate(itemExample.draftingStartDate,false,1)}
            <b> — </b>{formatDate(itemExample.draftingDueDate,false,1)}
          </div>
          <div><b>{daysRemaining} days remaining for due</b></div>
          <div><b>Description: </b>{itemExample.description}</div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '3px', alignItems: 'center' }}>
            <span><b>Design:</b> Est {itemExample.detailEst}hrs/Actual {detailActHours}hrs</span>
            <button className='secondary-button' style={{ minWidth: '36px', padding: '0px', border: 'none' }}
              onClick={() => enterEdit(true, false)}
              >{editIcon}
            </button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '3px', alignItems: 'center', visibility: itemExample.itemType === 'SPC' ? 'visible' : 'hidden' }}>
            <span><b>Checking:</b> Est {itemExample.checkerEst}hrs/Actual {checkActHours}hrs</span>
            <button className='secondary-button' style={{ minWidth: '36px', padding: '0px', border: 'none' }}
              onClick={() => enterEdit(true, true)}
              >{editIcon}
            </button>
          </div>
        </div>
        <div style={{ height: '1px', background: '#BFBFBF' }}></div>
        <div style={{ padding: '8px 16px', display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'initial', marginRight: '25px', justifyContent: 'flex-start' }}>
          <img style={{ width: '32px', height: '32px', borderRadius: '32px' }} alt="" src={imageDetailerUrl} />
          <div style={{ alignContent: 'center', marginRight: '8px' }}>
            {itemExample.designerDetail}<b>/DESIGN</b>
          </div>
        {itemExample.itemType === 'SPC' && (
          <>
          <img style={{ width: '32px', height: '32px', borderRadius: '32px' }} alt="" src={imageCheckerUrl} />
          <div style={{ alignContent: 'center', marginRight: '8px' }}>
            {itemExample.designerChecker}<b>/CHECKER</b>
          </div>
            </>
        )}
          {/* <div>
            <b>Drafting start/due: </b>{formatDate(itemExample.draftingStartDate,false,1)}
            <b> — </b>{formatDate(itemExample.draftingDueDate,false,1)}
          </div>
          <div><b>{daysRemaining} days remaining for due</b></div> */}
        </div>
        {/* {loading && ( <Loader></Loader> )} */}
        {openForm && editItem && editItem.orderDetailId && (
          <SidePanel
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmitOrderAssignation}
            item={editItem}
            onPriorityUpdate={handlePriorityUpdate}
            onDesignerCheckerHrsChange={handleDesignerCheckerHrsChange}
            onDesignerDetailHrsChange={handleDesignerDetailHrsChange}
            disabled= {false}
            myAssignedWorkInfo={estimateOption}
          />
        )}
      </div>
      )}

    </>
      )}

      {}
    </div>
  );
};


const TabComponentMyWork = () => {
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  };

  const [removeFilter, setRemoveFilter] = useState(false)
  const [dropdownFilters, setDropdownFilters] = useState([])


  const [filter, setFilter] = useState(null);
  const [startFilter, setStartFilter] = useState(null);
  const [filterOrders, setFilterOrders] = useState(null);
  const [prevFilter, setPrevFilter] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [emptyDate, setEmptyDate] = useState(startDate !== null);

  const handleResetTableFilters = () => {
    setFilter(null);
    setPrevFilter([]);
    setCurrentFilter([]);
  };

  const handleResetFilter = () => {
    handleResetTableFilters();
    setStartDate(null);
    setEndDate(null);
    setDateRange({ startDate: null, endDate: null });
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    setEmptyDate(true);
  }

  const [size, setSize] = React.useState("medium");
  const [fillMode, setFillMode] = React.useState("solid");
  const [rounded, setRounded] = React.useState("full");
  const dateFields = ["buildWeek", "draftingStartDate", "draftingDueDate", "loadDate"];

  const groupedFilters = filter && filter.filters ? filter.filters.reduce((acc, curr) => {
    if (!acc[curr.field]) {
      acc[curr.field] = [];
    }

    let formattedValue = curr.value;
    if (typeof curr.value === 'boolean') {
      // formating boolean value
      formattedValue = curr.value ? "Yes" : "No";
    } else if (dateFields.includes(curr.field)) {
      // formating date value
      formattedValue = new Date(curr.value) instanceof Date && !isNaN(new Date(curr.value))
        ? new Date(curr.value).toLocaleDateString('en-US')
        : curr.value;
    }

    acc[curr.field].push(formattedValue);
    return acc;
  }, {}) : {};

  const clearFilter = (field) => {
    handleRemoveAllFilters(field);
  };

  const extraData = { filtersLabel: ["Active filters:"]};

  const chipsData = {
    ...extraData,
    ...groupedFilters
  };


  const handleRemoveAllFilters = (currentFilterField) => {
    setFilter((prevFilter) => {
      if (!prevFilter) {
        return null;
      }

      // Remove the filter related to the current custom filter field
      const updatedFilters = prevFilter.filters.filter((filter) => filter.field !== currentFilterField);

      // If there are no filters left, return null to clear the filter
      return updatedFilters.length > 0 ? { ...prevFilter, filters: updatedFilters } : null;
    });

    setRemoveFilter(true);
    const allFields = filter?.filters?.map(filter => filter.field) || [];
    const updatedFields = allFields.filter(item => item !== currentFilterField);
    const allFields2 = [...new Set(updatedFields)];
    setPrevFilter(allFields2);
    setDropdownFilters(filter?.filters);
  };


  const [exportExcel, setExportExcel] = useState(false);

  const {accounts} = useMsal();
  const userName = accounts[0].name;

  const { token, profileImage } = useProfileData();
  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const [orders, setOrders] = useState([]);
  const { instance } = useMsal();
  const [sort, setSort] = useState([{ field: "draftingDueDate", dir: "asc" }]);
  const [employeeID, setEmployeeID] = useState(null);

  const [loadingEsoi, setLoadingEsoi] = useState(null);  

  //Initial filter
  const [modelTypes, setModelTypes] = useState([
    { text: 'ALL ASSIGNED', selected: true },
    { text: 'ASSIGNED FOR DETAIL', selected: false },
    { text: 'ASSIGNED FOR CHECK', selected: false },
    { text: 'RELEASED', selected: false }
  ]);

  const handleOptionClick = (index) => {
    const updatedOptions = modelTypes.map((option, i) => ({
      ...option,
      selected: i === index ? !option.selected : false
    }));

    // Ensure at least one option is always selected
    const isAnySelected = updatedOptions.some(option => option.selected);
    if (!isAnySelected) {
      updatedOptions[0].selected = true; // Default to 'ALL ASSIGNED'
    }
    setModelTypes(updatedOptions);

    //Create initial filter json
    const selectedOption = updatedOptions.find(option => option.selected);
    let filters = [];
    setSearchText("");

    if (selectedOption) {
      switch (selectedOption.text) {
        case 'ASSIGNED FOR DETAIL':
          filters = [
            { field: 'designerDetail', operator: 'contains', value: userName }
          ];                              
          break;
        case 'ASSIGNED FOR CHECK':
          filters = [
            { field: 'designerChecker', operator: 'contains', value: userName }
          ];                                
          break;
        case 'RELEASED':
          filters = [
            { field: 'esoistatusDesc', condition: 'AND', value: 'Released' },
            { field: 'desCheckerEmpId', condition: 'AND', value: employeeID },
            { field: 'desDetailEmpId', condition: 'OR', value: employeeID }
          ];
          setStartFilter(filters);
          setFilterOrders(null);
          return;
        case 'ALL ASSIGNED':
          filters = [
            { field: 'desCheckerEmpId', condition: 'AND', value: employeeID },
            { field: 'desDetailEmpId', condition: 'OR', value: employeeID }
          ];
          setStartFilter(filters);
          setFilterOrders(null);
          return;
        default:
          break;
      }
    }

    const filterObject = {
      logic: 'or',
      filters: filters
    };

    setStartFilter(null);
    setFilterOrders(filterObject);
  };
  //Initial filter
    
  const [department, setDepartment] = useState(getCurrentLine().ProdFamilyId);

  //Notice changes on current Line service
  useEffect(() => {
    const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {
      setDepartment(newCurrentLine.ProdFamilyId);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  //Function to make sure the filters have the needed fields
  const validateFilters = (finalFilter, filterOrders, startFilter) => {
    const hasRequiredFields = (filters) => {
      return filters?.some(
        (filter) =>
          filter.field === 'desCheckerEmpId' ||
          filter.field === 'desDetailEmpId' ||
          filter.field === 'designerDetail' ||
          filter.field === 'designerChecker'      
      );
    };
  
    return (
      hasRequiredFields(finalFilter?.filters) ||
      hasRequiredFields(filterOrders?.filters) ||
      hasRequiredFields(startFilter)
    );
  };

  const [totalRows, setTotalRows] = useState(0);

  const [totalEstimate, setTotalEstimate] = useState(0);
  const [totalActuals, setTotalActuals] = useState(0);
  const [productName, setProductName] = useState(getCurrentLine().ProdFamilyName);

  const fetchData = async (esoi, filtersInfo, orderBy) => {

    try {
      setLoadingEsoi(esoi); // Set the loading state to the current item's esoi
      //Combine filters
      let finalFilter = {
        logic: 'or',
        filters: []
      };

      if (filtersInfo && filtersInfo.filters && filtersInfo.filters.length > 0) {
        finalFilter.filters = [...filtersInfo.filters];
      }
    
      if (filterOrders && filterOrders.filters && filterOrders.filters.length > 0) {
        finalFilter.filters = [...finalFilter.filters, ...filterOrders.filters];
      }

      if (!validateFilters(finalFilter, filterOrders, startFilter)) {        
        return; 
      }
      
       if (token) {        
        const response = await axios.post(
          `/Department/pagedFilter/${encodeURIComponent('draftingDueDate')}`,
          {
            prodFamilyId: department,
            dateColumn: 'DraftingDueDate',
            sort: orderBy || 'asc',
            skip: 0,
            take: 1000,
            // myAssignedWorkOption : selectedOption,
            initFilter : JSON.stringify(startFilter, null, 2),
            // startDate: null,
            // endDate: null,
            filter: filtersInfo ? JSON.stringify(finalFilter, null, 2) : JSON.stringify(filterOrders, null, 2),
            viewAlias: 0,          
            productName: productName,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        setTotalRows(response.data.totalRows);

        const ordersWithIndex = response.data.items.map((order, index) => ({
          ...order,
          rowIndex: index + 1,
        }));

        //Calculate designer total hrs for detail
        // const totalDetailer = orders        
        // .filter(order => order.designerDetail ===  userName)
        // .reduce((acc, order) => acc + order.detailEst, 0);
        
        // const totalChecker = orders        
        // .filter(order => order.designerChecker ===  userName)
        // .reduce((acc, order) => acc + order.checkerEst, 0);  
        
        // // console.log('detailer total:',totalDetailer + totalChecker);
        // setTotalEstimate(totalDetailer + totalChecker);


        const uniqueEmployeePicURLs = new Map();
        let responseEmployee;
        const username = accounts[0].name;

        const ordersWithImages = await Promise.all(ordersWithIndex.map(async order => {

          let isDetailer = false;

          let employeeId = null;
          if(userName === order.designerChecker && userName !== order.designerDetail)
          {
            employeeId = order.desDetailEmpId;
            isDetailer = true;
          }
          else if(userName === order.designerDetail && userName !== order.designerChecker)
          {
            employeeId = order.desCheckerEmpId;
          }
          else {
            employeeId = order.desDetailEmpId;
          }

          if(employeeId)
          {
            const responseEmployee = await axios.get(`/GetEmployeePicURL/${employeeId}`, {
              headers: {
                Authorization: token,
              },
            });

            let imageCheckerUrl = '/DefaultProfile.png';
            let imageDetailerUrl = '/DefaultProfile.png';


            const employeePicUrl = responseEmployee.data.employeePicUrl;
            uniqueEmployeePicURLs.set(employeePicUrl, null);


            if (isDetailer) {
              imageDetailerUrl = employeePicUrl;
            } else {
              imageCheckerUrl = employeePicUrl;
            }

            return { ...order, imageCheckerUrl, imageDetailerUrl};
          }
        else{
          return { ...order, imageCheckerUrl: '/DefaultProfile.png', imageDetailerUrl: '/DefaultProfile.png'};
        }
          }));
          //Fetch imageUrls for uniqueEmployeePicURLs
          await Promise.all([...uniqueEmployeePicURLs.keys()].map(async checkerUrlImage => {
            const imgUrl = await downloadUserImg(checkerUrlImage);
            uniqueEmployeePicURLs.set(checkerUrlImage, imgUrl);
      }));

      // Update imageUrl for each comment based on corresponding uniqueEmployeePicURLs
      ordersWithImages.forEach(order => {

        if(order.designerDetail === username)
        {
          order.imageDetailerUrl = profileImage;
        }
        else
        {
          const imageDetailerUrl = uniqueEmployeePicURLs.get(order.imageDetailerUrl);
          order.imageDetailerUrl = imageDetailerUrl || '/DefaultProfile.png'; // default image if not found
        }

        if(order.designerChecker === username)
        {
          order.imageCheckerUrl = profileImage;
        }
        else
        {
          const imageCheckerUrl = uniqueEmployeePicURLs.get(order.imageCheckerUrl);
          order.imageCheckerUrl = imageCheckerUrl || '/DefaultProfile.png'; // default image if not found
        }

      });

      setOrders(ordersWithImages);

      }
    } catch (error) {

      if (error.response) {

        setToast({ showToast: true, message: error.response.data, type: 'error' });
        if(error.response.status === 401) LogOutService(instance);

      } else {
        setToast({ showToast: true, message:  error.message, type: 'error' });
      }
    }

    fetchTotalActualHours();
    setLoadingEsoi(null);
  };

  const fetchEmployeeID = async () => {
    if (token) {
      try {
        //get employee ID
        const response = await axios.get(`/GetEmployeeID/${accounts[0].username}`, {
          headers: {
            Authorization: token,
          },
        });

        setEmployeeID(response.data);

        const filters = [
          { field: 'desCheckerEmpId', condition: 'AND', value: response.data },
          { field: 'desDetailEmpId', condition: 'OR', value: response.data }
        ];
        setStartFilter(filters);
        setFilterOrders(null);

      } catch (error) {
        console.error("Error fetching data:", error);

      }
    } else {
      console.error("Error fetching data: Token not available");
    }
  };

  //Get total actual hrs
  const fetchTotalActualHours = async () => {
    if (token) {
      try {
        
        const url = `/getTotalHrs/${encodeURIComponent(accounts[0].username)}?PlantProdFamilyId=${encodeURIComponent(department)}`;

        const response = await axios.get(url, {
          headers: {
            Authorization: token,
          },
        });

        if (response.status === 200) {
          setTotalActuals(response.data.totalHrsActual);
          setTotalEstimate(response.data.totalHrsEstimate);
        }          
      } catch (error) {
        console.error("Error fetching data:", error);
      }    
    } else {
      console.error("Error fetching data: Token not available");
    }
  };

  useEffect(() => {
    // getCommentsData();
    if(employeeID !== null) fetchData();

    fetchTotalActualHours();
  }, [token, department]);

  useEffect(() => {
    fetchEmployeeID();
  }, [token]);

const downloadUserImg = async (imageName) => {
  if (!imageName) {
    return '/DefaultProfile.png';
  }

  if (token) {
    const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(imageName)}&departmentsFolder=${encodeURIComponent("")}&departmentFolder=${encodeURIComponent("")}&orderNumFolder=${""}&containerName=${encodeURIComponent("employeeprofileimages")}&isProfileImage=${encodeURIComponent(true)}`;
    try {
      const response = await axios.get(downloadUrl, {
        headers: {
          Authorization: token,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const imageUrl = URL.createObjectURL(blob);

      return imageUrl;
    } catch (error) {
      console.error('Error downloading user image:', error);
      return null;
    }
  }
};

const selectedOption = modelTypes.find(option => option.selected)?.text;
let totalRowsDetail1 = totalRows;
let totalRowsChecker1 = totalRows;
let totalRowsRelease1 = totalRows;


 const [totalRowsDetail, setTotalRowsDetail] = useState(totalRows);
 const [totalRowsChecker, setTotalRowsChecker] = useState(totalRows);
 const [totalRowsRelease, setTotalRowsRelease] = useState(totalRows);

  useEffect(() => {
    setTotalRowsDetail(totalRowsDetail1);    
    setTotalRowsChecker(totalRowsChecker1);  
    setTotalRowsRelease(totalRowsRelease1);
  }, [orders, token]);

  const renderMyWorkCards =  (section) => {
    

    // Split into sections when the current option is All assigned
    let filteredOrders = orders;

    if (selectedOption === 'ALL ASSIGNED') {

      if (section === 'ASSIGNED FOR DETAIL' ) {
        filteredOrders = orders.filter(order => order.esoistatusDesc !== 'Released' && order.designerDetail === userName);        
        totalRowsDetail1 = filteredOrders.length;        
      }
      else if (section === 'ASSIGNED FOR CHECK') {
        filteredOrders = orders.filter(order => order.esoistatusDesc !== 'Released' && order.designerChecker === userName);        
        totalRowsChecker1 = filteredOrders.length;
      }
      else if (section === 'RELEASED') {
        filteredOrders = orders.filter(order => order.esoistatusDesc === 'Released');
        totalRowsRelease1 = filteredOrders.length;
        
      }
    }



  return filteredOrders.map((order, index) => (
    <MyWorkCard
      key={index}
      itemExample={order}
      imageUrl={order.imageUrl}
      fetchData={() => fetchData(order.esoi)} // Pass the specific esoi to fetchData
      loading={loadingEsoi === order.esoi} // Pass loading state specific to this item
      imageDetailerUrl={order.imageDetailerUrl}
      imageCheckerUrl ={order.imageCheckerUrl}
    />
    ));
  };

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 600); // delay

  const handleSearch = (event) => {    
    setSearchText(event.target.value);
  };

  useEffect(() => {
    let newFilter;
    if (debouncedSearchText) {
      newFilter = { field: 'esoi', operator: 'contains', value: debouncedSearchText };
    } else {
      newFilter = { field: 'esoi', operator: 'contains', value: '' }; 
    }
  
    setFilterOrders(prevFilterOrders => {
      let currentFilters = prevFilterOrders?.filters || [];
      currentFilters = currentFilters.filter(filter => filter.field !== 'esoi');
      let combinedFilters = [...currentFilters, newFilter];
  
      const filterObject = {
        logic: 'or',
        filters: combinedFilters
      };
  
      return filterObject;
    });
  }, [debouncedSearchText]);

  return (
    <div className='roboto-19-700' style={{ paddingLeft: '30px', paddingRight: '30px', display:'flex', flexDirection: 'row', gap:'24px'}}>    
    <FiltersMAW      
      fetchData={fetchData}
      filterOrders={filterOrders}
      startFilter={startFilter}
      actualHours = {totalActuals} 
      estimateHours = {totalEstimate}    
      >
    </FiltersMAW>
    <div className='roboto-19-700' style={{ display:'flex', flexDirection: 'column', gap:'24px', width: '100%'}}>

      {/* Initial filters */}
      <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
        <div style={{ display: 'flex', gap:'10px', alignItems: 'center'}}>
          <div className={"chipList k-form-field-wrap"} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div className='roboto-15-400' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
            <TextBox
              className= 'roboto-15-400'
              value={searchText}
              onChange={handleSearch}
              placeholder="Search ESOI..."
              style={{ width: '250px', margin: '0px 50px 0px 0px'}}
              prefix={() => (
                <InputPrefix>
                  <SvgIcon icon={searchIcon} />
                </InputPrefix>
              )}
            />
              <b>SEE:</b>
              {modelTypes.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleOptionClick(index)}
                  style={{
                    backgroundColor: option.selected ? '#0097E0' : 'transparent',
                    color: option.selected ? 'white' : 'inherit',
                    padding: '0px 10px 0px 10px',
                    margin: '8px',
                    border: option.selected ?  '1px solid transparent' : '1px solid #EBEBEB',
                    borderRadius: '180px',
                    height: '24px',
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex', flexDirection: 'row'
                  }}
                >
                  {option.text}
                  <div style={{display: option.selected ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center', marginLeft: '8px'}}>{checkmark}</div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Initial filter */}
      {/* Cards section */}
      {(selectedOption === 'ASSIGNED FOR DETAIL' || selectedOption === 'ALL ASSIGNED') && (
        <React.Fragment>
          <p className='MAW-titles'>Assigned for detailing ({totalRowsDetail})</p>
          {renderMyWorkCards('ASSIGNED FOR DETAIL')}
        </React.Fragment>
      )}

      {(selectedOption === 'ASSIGNED FOR CHECK' || selectedOption === 'ALL ASSIGNED') && (
        <React.Fragment>
          <p className='MAW-titles'>Assigned for check ({totalRowsChecker})</p>
          {renderMyWorkCards('ASSIGNED FOR CHECK')}
        </React.Fragment>
      )}

      {(selectedOption === 'RELEASED' || selectedOption === 'ALL ASSIGNED') && (
        <React.Fragment>
          <p className='MAW-titles'>Marked as released ({totalRowsRelease})</p>
          {renderMyWorkCards('RELEASED')}
        </React.Fragment>
      )}
      {/* Cards section */}
    </div>
    </div>
  );
};

export default TabComponentMyWork;